import styled from 'styled-components'
import { H3Styled, H2Styled } from '../../../../../components/Typography'
import breakpoints from '../../../../../constants/breakpoints'
import Button from '../../../../../components/Button'

export const Container = styled.section`
  background: ${(props) => props.theme.colors.background};
  position: relative;
  color: ${(props) => props.theme.colors.text};
  overflow: hidden;
`

export const Content = styled.div`
  padding: 0px 24px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  @media screen and ${breakpoints.medium} {
    padding: 0px 40px 0px 0px;
  }

  @media screen and ${breakpoints.xlarge} {
    max-width: 1760px;
    margin: 0 auto;
    padding: 0px;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Col1 = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 38px;

  @media screen and ${breakpoints.medium} {
    height: 100%;
    width: 100%;
  }
`

export const Col2 = styled.div`
  width: 100%;

  @media screen and ${breakpoints.medium} {
    max-width: clamp(381px, 34%, 568px);
  }
`

export const ContainerEssential = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #f3f3f3 0%, #eaf0f0 57.64%);
  transition: opacity 0.5s ease-out;
  opacity: ${(props) => (props.theme.title === 'essential' ? 1 : 0)};
`

export const ContainerVersatile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #ede9e4 0%,
    #dcd9d4 74.53%,
    #e4e1dc 100%
  );
  transition: opacity 0.5s ease-out;
  opacity: ${(props) => (props.theme.title === 'versatile' ? 1 : 0)};
`

export const ContainerProfessional = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #363636 0%, #070906 71.78%);
  transition: opacity 0.5s ease-out;
  opacity: ${(props) => (props.theme.title === 'professional' ? 1 : 0)};
`
export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and ${breakpoints.medium} {
    flex-direction: row;
    min-height: 100vh;
    justify-content: space-between;
  }

  @media screen and ${breakpoints.xsmall} {
    padding: 25px 0px;
  }
`
export const DivDescription = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  text-align: center;

  @media screen and ${breakpoints.medium} {
    row-gap: 24px;
    text-align: left;
  }
`

export const Title = styled(H3Styled)`
  letter-spacing: 0.02em;
`

export const TitlePlanOverview = styled(H2Styled)`
  letter-spacing: 0.02em;
`

export const Link = styled.a`
  color: #027aae;
  text-decoration: none;
  cursor: pointer;
`
export const Description = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`
export const OptionGroup = styled.fieldset`
  border: none;
  appearance: none;
  box-sizing: border-box;
  margin: 32px 0px;
  text-align: left;

  & > legend {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
`

export const Background = styled.div`
  background-image: url(${(props) => props.theme.images.background.extraSmall});
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-repeat: no-repeat;
  height: 100%;
  background-position-y: bottom;
  background-position-x: left;

  @media screen and ${breakpoints.medium} {
    background-image: url(${(props) => props.theme.images.background.medium});
  }

  @media screen and ${breakpoints.large} {
    background-image: url(${(props) => props.theme.images.background.larger});
  }

  @media screen and ${breakpoints.xlarge} {
    background-image: url(${(props) =>
      props.theme.images.background.extraLarge});
  }
`
export const BottomDescription = styled.p`
  text-align: center;

  @media screen and ${breakpoints.medium} {
    text-align: left;
  }
`
