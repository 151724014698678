import React, { useMemo, useContext } from 'react'
import { JarvisOnboardingComponent } from '@jarvis/react-user-onboarding/dist/components'
import { ShellContext } from '../../contexts/shell.context'

type Props = {
  onFinished(error?: unknown): void
}

export const Onboarding = ({ onFinished }: Props) => {
  const { stack, authProvider } = useContext(ShellContext)

  const overWritedAuthProvider = useMemo(
    () => ({
      ...authProvider,
      onTokenExchangeRequired: async () => authProvider.getAccessToken(true)
    }),
    [authProvider]
  )

  return (
    <JarvisOnboardingComponent
      baseURLProvider={stack}
      authProvider={overWritedAuthProvider}
      language="en"
      country="US"
      onFinished={onFinished}
    />
  )
}
