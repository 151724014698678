import React from 'react'

const IconTruck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M3,8.5001831c0-0.2763672,0.2236328-0.5,0.5-0.5h0.4223022l0.2632446-1.0532227
	C4.324707,6.3893433,4.8232422,6.0001831,5.3979492,6.0001831h9.2368164c0.3632812,0,0.7070312,0.1572266,0.9453125,0.4311523
	c0.2373047,0.2739258,0.34375,0.6367188,0.2929688,0.9956055l-0.8779297,6.1435547
	c-0.0361328,0.25-0.25,0.4296875-0.4941406,0.4296875c-0.0234375,0-0.0478516-0.0019531-0.0712891-0.0048828
	c-0.2734375-0.0390625-0.4638672-0.2919922-0.4248047-0.5654297l0.8779297-6.1445312
	c0.0136719-0.0981445-0.0302734-0.1665039-0.0585938-0.1987305s-0.0898438-0.0864258-0.1894531-0.0864258H5.3979492
	c-0.1147461,0-0.2148438,0.0776367-0.2426758,0.1889648L4.9525757,8.0001831H7.5c0.2763672,0,0.5,0.2236328,0.5,0.5
	s-0.2236328,0.5-0.5,0.5h-4C3.2236328,9.0001831,3,8.7765503,3,8.5001831z M5.5,12.0001831h-2c-0.2763672,0-0.5,0.2236328-0.5,0.5
	s0.2236328,0.5,0.5,0.5h2c0.2763672,0,0.5-0.2236328,0.5-0.5S5.7763672,12.0001831,5.5,12.0001831z M9,10.5001831
	c0-0.2763672-0.2236328-0.5-0.5-0.5h-6c-0.2763672,0-0.5,0.2236328-0.5,0.5s0.2236328,0.5,0.5,0.5h6
	C8.7763672,11.0001831,9,10.7765503,9,10.5001831z M21.890625,9.40448l-0.3935547,3.1508789
	c-0.0009766,0.0048828-0.0009766,0.0097656-0.0019531,0.0146484l-0.4990234,3.9926758
	c-0.03125,0.25-0.2441406,0.4375-0.4960938,0.4375h-1c-0.2763672,0-0.5-0.2236328-0.5-0.5s0.2236328-0.5,0.5-0.5h0.5585938l0.375-3
	H19c-0.1435547,0-0.2802734-0.0615234-0.375-0.1689453s-0.1386719-0.2509766-0.1210938-0.3930664l0.4296875-3.4379883h-1.0917969
	c-0.1240234,0-0.2314453,0.0927734-0.2480469,0.215332l-0.5986328,4.3530273
	c-0.0371094,0.2744141-0.2949219,0.4648438-0.5634766,0.4267578c-0.2734375-0.0371094-0.4648438-0.2900391-0.4267578-0.5634766
	l0.5986328-4.3525391c0.0839844-0.6147461,0.6162109-1.0791016,1.2382812-1.0791016h2.8085938
	c0.3583984,0,0.6992188,0.1538086,0.9365234,0.4223633S21.9345703,9.0490112,21.890625,9.40448z M20.8378906,9.0846558
	c-0.0283203-0.0317383-0.0898438-0.0844727-0.1875-0.0844727h-0.7089844l-0.375,3h0.9921875l0.3398438-2.7192383
	C20.9101562,9.1837769,20.8652344,9.1159058,20.8378906,9.0846558z M4.8056641,16.0343628l-0.2739258-0.1064453
	c-0.105957-0.0410156-0.1708984-0.1474609-0.1582031-0.2597656l0.1235352-1.1123047
	c0.0302734-0.2753906-0.1674805-0.5224609-0.4418945-0.5527344C3.78125,13.9786987,3.5332031,14.1710815,3.5029297,14.444519
	l-0.1235352,1.1132812c-0.0625,0.5644531,0.2641602,1.1005859,0.7924805,1.3027344l0.2724609,0.1054688
	C4.5039062,16.9894409,4.5649414,17.0001831,4.625,17.0001831c0.2001953,0,0.3896484-0.1210938,0.4663086-0.3193359
	C5.190918,16.4230347,5.0629883,16.1339722,4.8056641,16.0343628z M12.5,16.0001831h-1c-0.2763672,0-0.5,0.2236328-0.5,0.5
	s0.2236328,0.5,0.5,0.5h1c0.2763672,0,0.5-0.2236328,0.5-0.5S12.7763672,16.0001831,12.5,16.0001831z M10,17.0001831
	c0,1.1025391-0.8969727,2-2,2s-2-0.8974609-2-2s0.8969727-2,2-2S10,15.897644,10,17.0001831z M9,17.0001831
	c0-0.5517578-0.4487305-1-1-1s-1,0.4482422-1,1s0.4487305,1,1,1S9,17.5519409,9,17.0001831z M18,17.0001831
	c0,1.1025391-0.8974609,2-2,2s-2-0.8974609-2-2s0.8974609-2,2-2S18,15.897644,18,17.0001831z M17,17.0001831
	c0-0.5517578-0.4482422-1-1-1s-1,0.4482422-1,1s0.4482422,1,1,1S17,17.5519409,17,17.0001831z"
      >
        <title>Truck</title>
      </path>
    </svg>
  )
}

export default IconTruck
