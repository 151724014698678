import React from 'react'

import { TranslatorFunctionType } from '../../../../types/localization'
import useMediaQuery from '../../../../hooks/useMediaQuery'

import {
  Container,
  DivText,
  Title,
  DivCarbonNeutral,
  TextCarbonNeutral,
  IconCarbonNeutral,
  DivImage,
  Image,
  BackgroundCarbonNeutral,
  DivContent
} from './Recycling.styles'

import Icon from './assets/img/Icon.svg'

import ImgM from './assets/img/Img-m.jpg'
import ImgL from './assets/img/Img-l.jpg'
import ImgXL from './assets/img/Img-xl.jpg'

export const Recycling = ({ t }: { t: TranslatorFunctionType }) => {
  const isMobile = useMediaQuery('(min-width: 1024px)')

  return (
    <Container>
      <DivContent>
        <DivText>
          <Title>{t('recycling.title', 'Print responsibly')}</Title>
        </DivText>
        <BackgroundCarbonNeutral>
          <DivCarbonNeutral>
            <TextCarbonNeutral>
              {t(
                'recycling.description',
                'Your HP Instant Ink Platinum service carries a carbon neutral usage certification and includes responsible ink cartridge recycling. We even provide pre-paid cartridge-return envelopes to make recycling easy. Together, we can reduce plastic waste and help protect the planet.'
              )}
            </TextCarbonNeutral>
            <IconCarbonNeutral src={Icon} />
          </DivCarbonNeutral>
        </BackgroundCarbonNeutral>
      </DivContent>
      {isMobile && (
        <DivImage>
          <picture>
            <source srcSet={ImgXL} media="(min-width: 1920px)" />
            <source srcSet={ImgL} media="(min-width: 1440px)" />
            <Image src={ImgM} alt="benefits-banner" />
          </picture>
        </DivImage>
      )}
    </Container>
  )
}
