import React, { useEffect } from 'react'
import IconClose from '../Icons/IconClose'

import {
  Background,
  CloseModalButton,
  Container,
  ModalWrapper
} from './Modal.styles'

interface Props {
  isOpen: boolean
  children: JSX.Element[] | JSX.Element
  closeModal: () => void
}

export const Modal = ({ isOpen, children, closeModal }: Props) => {
  useEffect(() => {
    const bodyElement = document.querySelector('body')
    if (isOpen) {
      bodyElement.setAttribute('style', 'overflow: hidden')
    }
    return () => bodyElement.removeAttribute('style')
  }, [isOpen])

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeModal()
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [closeModal])

  return (
    <ModalWrapper isOpen={isOpen}>
      <Background />
      <Container>
        {children}
        <CloseModalButton
          aria-label="Close modal"
          onClick={closeModal}
          data-testid="close-modal-button"
        >
          <IconClose />
        </CloseModalButton>
      </Container>
    </ModalWrapper>
  )
}
