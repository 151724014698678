import styled from 'styled-components'
import { H2Styled } from '../../../../components/Typography'
import breakpoints from '../../../../constants/breakpoints'

export const Image = styled.img`
  width: 100%;
  vertical-align: bottom;
`
export const Container = styled.section`
  margin: 0;
  box-sizing: border-box;
  background-color: #0a0e11;
  color: #fff;
  width: 100%;
  @media screen and ${breakpoints.medium} {
    display: flex;
  }
`
export const DivImg = styled.div`
  @media screen and ${breakpoints.medium} {
    width: 50%;
    flex: 0 0 50%;
  }
`
export const Content = styled.div`
  @media screen and ${breakpoints.medium} {
    padding: 0;
  }
`
export const DivText = styled.div`
  padding: 64px 20px;
  box-sizing: border-box;
  @media screen and ${breakpoints.medium} {
    width: 50%;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    padding: 0 12px 0 44px;
  }

  @media screen and ${breakpoints.large} {
    padding: 0 127px 0 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0 163px 0 80px;
  }
`
export const Title = styled(H2Styled)`
  text-align: left;
  margin-bottom: 27px;
  letter-spacing: 0.02em;

  @media screen and ${breakpoints.medium} {
    margin-bottom: 24px;
  }
  @media screen and ${breakpoints.large} {
    margin-bottom: 32px;
  }
  @media screen and ${breakpoints.xlarge} {
    margin-bottom: 40px;
  }
`
export const Description = styled.p`
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: white;
  @media screen and ${breakpoints.large} {
    font-size: 20px;
    line-height: 28px;
  }
`
