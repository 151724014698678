import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { OptionButton } from '../../../../components/OptionButton/OptionButton'

import {
  Image,
  Col1,
  Col2,
  Section,
  Title,
  Link,
  DivDescription,
  Description,
  Wrapper,
  OptionGroup,
  BottomDescription
} from '../Common/Styles/ProductPage.styles'
import { TranslatorFunctionType } from '../../../../types/localization'
import useAnalytics from '../../../../hooks/useAnalytics'

interface Props {
  t: TranslatorFunctionType
  handlePlanChange: (event: EventTarget) => void
  openComparisonModal: () => void
  plan: string
}

export const ChooseYourPrinter = ({
  t,
  handlePlanChange,
  openComparisonModal,
  plan
}: Props) => {
  const { images, title, description } = useContext(ThemeContext)
  const { events, publishEvent } = useAnalytics()

  const openModal = () => {
    publishEvent(events.ClickComparisonTable())
    openComparisonModal()
  }

  return (
    <Section>
      <Col1>
        <picture>
          <source
            srcSet={images.printer.extraLarge}
            media="(min-width: 1920px)"
          />
          <source srcSet={images.printer.larger} media="(min-width: 1440px)" />
          <source srcSet={images.printer.medium} media="(min-width: 1024px)" />
          <Image src={images.printer.extraSmall} alt="benefits-banner" />
        </picture>
      </Col1>
      <Col2>
        <DivDescription>
          <Title>
            {t('product-page.choose-your-printer.title', 'Choose your printer')}
          </Title>
          <Description>
            {t(
              `product-page.choose-your-printer.description-${title}`,
              description
            )}
          </Description>
        </DivDescription>
        <Wrapper>
          <OptionGroup>
            <OptionButton
              name={'printer'}
              checked={plan === 'Essential'}
              value={'Essential'}
              description={'Essential'}
              label={'HP Envy'}
              testId="plan-option-1"
              onChangeValue={handlePlanChange}
            />
            <OptionButton
              name={'printer'}
              checked={plan === 'Versatile'}
              value={'Versatile'}
              description={'Versatile'}
              label={'HP Envy Inspire'}
              testId="plan-option-2"
              onChangeValue={handlePlanChange}
            />
            <OptionButton
              name={'printer'}
              checked={plan === 'Professional'}
              value={'Professional'}
              description={'Professional'}
              label={'HP Officejet Pro'}
              testId="plan-option-3"
              onChangeValue={handlePlanChange}
            />
          </OptionGroup>
        </Wrapper>
        <BottomDescription>
          {t(
            'product-page.choose-your-printer.bottom-description',
            'Can’t decide? '
          )}
          <Link onClick={openModal} testId="comparison-table-button-open">
            {t(
              'product-page.choose-your-printer.comparison-table-btn',
              'Compare printers'
            )}
          </Link>
        </BottomDescription>
      </Col2>
    </Section>
  )
}
