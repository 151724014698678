import styled from 'styled-components'
import breakpoints from '../../constants/breakpoints'

export const Container = styled.footer`
  font-size: 16px;
  line-height: 24px;
  font-family: Forma DJR Micro;
  color: #fff;
  background-color: #212121;
  text-align: center;
  padding: 32px 20px;

  @media ${breakpoints.large} {
    font-size: 14px;
    line-height: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
  }
`

export const LinkGroup = styled.div`
  margin-bottom: 5px;

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover,
  a:focus-visible {
    text-decoration: underline;
  }

  span {
    margin: 0 5px;
  }
`
