import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'

import { TranslatorFunctionType } from '../../../../types/localization'
import useMediaQuery from '../../../../hooks/useMediaQuery'

import {
  Container,
  Wrapper,
  Image,
  Col,
  List,
  Step,
  Title,
  Description
} from './HowItWorks.styles'

import CoverImgXS from './assets/hiw-xs.jpg'
import CoverImgM from './assets/hiw-m.jpg'
import CoverImgL from './assets/hiw-l.jpg'
import CoverImgXL from './assets/hiw-xl.jpg'

export const HowItWorks = ({ t }: { t: TranslatorFunctionType }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLOListElement>(null)

  const shouldAnimate = useMediaQuery('(min-width: 1024px)')

  useEffect(() => {
    const listElement = listRef.current
    const tl = gsap.timeline({ paused: true })
    const children = Array.from(listRef.current.children)

    if (!shouldAnimate) {
      gsap.set(children, { clearProps: 'all' })
      gsap.set(listRef.current, { clearProps: 'all' })
      return
    }

    children.slice(0, children.length - 1).forEach((_, idx) => {
      tl.to(listElement, { autoAlpha: 0.99, duration: 2 })
      tl.to(listElement, { y: '-=32vh' }, '>')
      tl.to(children, { autoAlpha: 0.15 }, '<')
      tl.to(children[idx + 1], { autoAlpha: 1 }, '<')

      if (idx === children.length - 2) {
        tl.to(listElement, { autoAlpha: 1, duration: 2 }, '<')
      }
    })

    const handleScroll = () => {
      const maxScrollHeight =
        containerRef.current.offsetHeight - wrapperRef.current.offsetHeight
      const scrollOffset =
        wrapperRef.current.offsetTop - containerRef.current.offsetTop

      const progress = Math.floor((scrollOffset / maxScrollHeight) * 100)

      tl.progress(progress / 100)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      tl.clear(true).kill()
      window.removeEventListener('scroll', handleScroll)
    }
  }, [shouldAnimate])

  return (
    <Container
      ref={containerRef}
      aria-label={t('how-it-works.title', 'How it works')}
    >
      <Wrapper ref={wrapperRef}>
        <Col overflowHidden={true}>
          <picture>
            <source srcSet={CoverImgXL} media="(min-width: 1760px)" />
            <source srcSet={CoverImgL} media="(min-width: 1360px)" />
            <source srcSet={CoverImgM} media="(min-width: 944px)" />
            <Image src={CoverImgXS} alt="benefits-banner" />
          </picture>
        </Col>
        <Col>
          <List ref={listRef} data-testid="choose-your-printer-list">
            <Step>
              <div>
                <Title>
                  {t('how-it-works.steps.step1.title', 'Choose a new printer')}
                </Title>
                <Description>
                  {t(
                    'how-it-works.steps.step1.description',
                    'Pick from the latest HP models as part of your subscription.'
                  )}
                </Description>
              </div>
            </Step>

            <Step>
              <div>
                <Title>
                  {t('how-it-works.steps.step2.title', 'Pick a print plan')}
                </Title>
                <Description>
                  {t(
                    'how-it-works.steps.step2.description',
                    "Your printer will automatically reorder ink when you're low."
                  )}
                </Description>
              </div>
            </Step>

            <Step>
              <div>
                <Title>
                  {t('how-it-works.steps.step3.title', 'Add paper delivery')}
                </Title>
                <Description>
                  {t(
                    'how-it-works.steps.step3.description',
                    'Never run out of paper again.'
                  )}
                </Description>
              </div>
            </Step>

            <Step>
              <div>
                <Title>
                  {t(
                    'how-it-works.steps.step4.title',
                    'Start your subscription'
                  )}
                </Title>
                <Description>
                  {t(
                    'how-it-works.steps.step4.description',
                    'Enjoy a 30 day risk-free trial.'
                  )}
                </Description>
              </div>
            </Step>
          </List>
        </Col>
      </Wrapper>
    </Container>
  )
}
