import React, { useEffect, useState } from 'react'
import { MfePropsType } from '../../types/mfeProps'

import useAnalytics from '../../hooks/useAnalytics'

import VisibilityObserver from '../../components/VisibilityObserver'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FAQs from '../../components/FAQs'
import Disclaimers from '../../components/Disclaimers'
import Hero from './components/Hero'
import ChooseYourPlan from './components/ChooseYourPlan'
import Subscription from './components/Subscription'
import Benefits from './components/Benefits'
import PrinterUpgrades from './components/PrinterUpgrades'
import HowItWorks from './components/HowItWorks'
import Recycling from './components/Recycling'
import FloatingGetStarted from './components/FloatingGetStarted'
import { Global as globalConfig } from '../../configs/global'

export const LandingPage = ({ t }: MfePropsType) => {
  const { events, publishEvent, publishEventPromise } = useAnalytics()
  const [sectionDisplayed, setSectionDisplayed] = useState('Top')
  const { enrollPath } = globalConfig

  const FloatingButtonClick = () => {
    publishEventPromise(
      events.ChoosePlanFloatingFooter({ screenMode: sectionDisplayed })
    ).then(() => {
      window.location.href = enrollPath
    })
  }

  useEffect(() => {
    publishEvent(events.LandingPageDisplayed())
    const onboardigHeader = document.querySelector(
      '[data-testid="jarvis-react-shell-common-layout__header"]'
    )
    if (onboardigHeader) {
      onboardigHeader.remove()
    }
  }, [])

  return (
    <>
      <Header />
      <VisibilityObserver
        observerOptions={{ threshold: 0.51 }}
        onVisible={() => {
          setSectionDisplayed('Top')
        }}
      >
        <Hero {...{ t }} />
      </VisibilityObserver>
      <main>
        <VisibilityObserver
          observerOptions={{ threshold: 0.51 }}
          onVisible={() => {
            setSectionDisplayed('SimplestWayToPrint')
          }}
          displayedEvent={events.ChooseYourPlanDisplayed}
        >
          <ChooseYourPlan {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.51 }}
          onVisible={() => {
            setSectionDisplayed('OptionForEveryone')
          }}
          displayedEvent={events.SubscriptionDisplayed}
        >
          <Subscription {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.5 }}
          onVisible={() => {
            setSectionDisplayed('ConvenientFlexibleSustainable')
          }}
          displayedEvent={events.BenefitsDisplayed}
        >
          <Benefits {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.51 }}
          onVisible={() => {
            setSectionDisplayed('PrinterUpgrades')
          }}
          displayedEvent={events.PrinterUpgradesDisplayed}
        >
          <PrinterUpgrades {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.46 }}
          onVisible={() => {
            setSectionDisplayed('ChooseActivatePrint')
          }}
          displayedEvent={events.HowItWorksDisplayed}
        >
          <HowItWorks {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.51 }}
          onVisible={() => {
            setSectionDisplayed('ProtectThePlanet')
          }}
          displayedEvent={events.RecyclingDisplayed}
        >
          <Recycling {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.51 }}
          onVisible={() => {
            setSectionDisplayed('Faqs')
          }}
          displayedEvent={events.FAQsDisplayed}
        >
          <FAQs {...{ t }} />
        </VisibilityObserver>
        <VisibilityObserver
          observerOptions={{ threshold: 0.51 }}
          onVisible={() => {
            setSectionDisplayed('Disclaimers')
          }}
          displayedEvent={events.DisclaimersDisplayed}
        >
          <Disclaimers {...{ t }} />
        </VisibilityObserver>
        <FloatingGetStarted onClick={FloatingButtonClick} {...{ t }} />
      </main>
      <Footer {...{ t }} />
    </>
  )
}
