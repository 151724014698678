import styled from 'styled-components'
import { H2Styled } from '../../../../components/Typography'
import breakpoints from '../../../../constants/breakpoints'

export const Container = styled.section`
  padding: 64px 20px;
  border-sizing: border-box;

  @media screen and ${breakpoints.medium} {
    display: flex;
    justify-content: space-between;
    padding: 120px 40px 93px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 180px 80px;
  }
`

export const DivImg = styled.div`
  @media screen and ${breakpoints.medium} {
    flex: 0 0 50%;
  }

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }
`

export const DivText = styled.div`
  box-sizing: border-box;
  margin-bottom: 64px;

  @media screen and ${breakpoints.medium} {
    flex: 0 0 40%;
  }
`

export const Title = styled(H2Styled)`
  margin: 32px 0px 0px;

  @media screen and ${breakpoints.medium} {
    margin: 40px 0px 0px;
  }

  @media screen and ${breakpoints.large} {
    margin: 28px 0px 0px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin: 42px 0px 0px;
  }
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin: 24px 0px 32px;

  @media screen and ${breakpoints.medium} {
    margin: 32px 0px 40px;
  }

  @media screen and ${breakpoints.large} {
    font-size: 20px;
    line-height: 32px;
    margin: 12px 0px 36px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin: 18px 0px 46px;
  }
`

export const LiStyled = styled.li`
  border-top: 1px solid #212121;

  & :last-child {
    border-bottom: 1px solid #212121;
  }
`
