import styled from 'styled-components'
import { H2Styled } from '../../../../components/Typography'
import breakpoints from '../../../../constants/breakpoints'

import ImgXS from './assets/img/Img-xs.jpg'

export const Container = styled.section`
  background-color: #eaeaec;
  color: white;
  font-style: normal;
  font-weight: 100;
  display: flex;
  flex-direction: column;

  @media screen and ${breakpoints.medium} {
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
  }
`
export const DivContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${breakpoints.medium} {
    width: 50%;
    height: 100%;
    justify-content: center;
  }
`
export const DivText = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 64px 20px 0px;
  color: #212121;

  @media screen and ${breakpoints.medium} {
    margin: 0 auto;
    justify-content: center;
    padding: 0px 10px 0 40px;
  }

  @media screen and ${breakpoints.large} {
    padding: 0px 127px 0 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0px 166px 0 81px;
  }
`

export const Title = styled(H2Styled)``

export const BackgroundCarbonNeutral = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px 200px;
  color: #212121;

  @media screen and ${breakpoints.medium} {
    margin: 0 auto;
    justify-content: center;
    padding: 0px 10px 0 40px;
  }

  @media screen and ${breakpoints.large} {
    padding: 0px 127px 0 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0px 166px 0 81px;
  }

  @media screen and ${breakpoints.xsmall} {
    background-image: url(${ImgXS});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
  }
`
export const DivCarbonNeutral = styled.div`
  background-color: #ffffff;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  box-sizing: border-box;
  border-radius: 10px;

  @media screen and ${breakpoints.medium} {
    margin-top: 32px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin-top: 48px;
  }
`
export const TextCarbonNeutral = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`
export const IconCarbonNeutral = styled.img`
  margin-left: 12px;
`

export const DivImage = styled.div`
  height: 429px;

  @media screen and ${breakpoints.medium} {
    width: 50%;
    height: 100%;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
