import useShell from './useShell'
import EnvConfig from '../configs/env'
import {
  InstantInkSubscriptionsType,
  SubscriptionType
} from '../types/subscriptions'
import {
  CatalogGatewayParsedContentType,
  CatalogGatewayParsedType
} from '../types/catalogGatewayParsed'

export const useInkSubscriptions = () => {
  const shell = useShell()
  const env = EnvConfig[shell.stack]

  const getInstankInkSubscriptions = async (): Promise<
    InstantInkSubscriptionsType[]
  > => {
    const token = await shell.authProvider.getAccessToken()

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await fetch(env.SUBSCRIPTIONS_URL, requestOptions)
    if (response.ok === false) {
      return []
    }

    const { instantInkSubscriptions }: SubscriptionType = await response.json()
    return instantInkSubscriptions
  }

  const getSuggestedChoices = async (catalogData: CatalogGatewayParsedType) => {
    const instantInkSubscriptions = await getInstankInkSubscriptions()

    if (instantInkSubscriptions.length <= 0) return

    const highestPlanPagesPerMonth = instantInkSubscriptions
      .filter(
        ({ state }) => state === 'subscribed' || state === 'subscribed_no_pens'
      )
      .map(({ planPagesPerMonth }) => planPagesPerMonth)
      .sort((a, b) => b - a)[0]

    const planContents = []

    Object.keys(catalogData).forEach((planName: string) => {
      catalogData[planName].forEach(
        (content: CatalogGatewayParsedContentType) =>
          planContents.push({
            ...content,
            match: Math.abs(parseInt(content.pages) - highestPlanPagesPerMonth)
          })
      )
    })

    const nearlyPaasPagesPerMonth = planContents
      .sort((a, b) => a.match - b.match)
      .map(({ id }) => id)[0]

    let planSuggested: CatalogGatewayParsedContentType = {}

    Object.keys(catalogData).every((planName) => {
      planSuggested = catalogData[planName].find(
        ({ id }) => id === nearlyPaasPagesPerMonth
      )

      if (!!planSuggested === true) {
        planSuggested.planName = planName
        return false
      }
      return true
    })

    return planSuggested
  }

  return { getSuggestedChoices }
}
