import React from 'react'
import { TranslatorFunctionType } from '../../types/localization'

import {
  DescriptionText,
  Description,
  DivText,
  DivImg,
  Image,
  Title,
  Container
} from './HowDoIKnowPapersModal.styles'

import BannerXL from './assets/images/banner-xl.jpg'
import BannerL from './assets/images/banner-l.jpg'
import BannerM from './assets/images/banner-m.jpg'
interface Props {
  t: TranslatorFunctionType
}

export const HowDoIKnowPapersModal = ({ t }: Props) => {
  return (
    <>
      <Container>
        <DivImg>
          <picture>
            <source srcSet={BannerXL} media="(min-width: 1920px)" />
            <source srcSet={BannerL} media="(min-width: 1440px)" />
            <source srcSet={BannerM} media="(min-width: 1024px)" />
            <Image src={BannerM} />
          </picture>
        </DivImg>
        <DivText>
          <Title>
            {t(
              'how-do-i-know-papers-modal.title',
              'Paper Delivery for Instant Ink Platinum'
            )}
          </Title>
          <Description>
            <DescriptionText>
              {t(
                'how-do-i-know-papers-modal.description1',
                `Add paper to your subscription and enjoy the convenience of automatic paper delivery when you’re running low. Never run out of HP paper again.`
              )}
            </DescriptionText>
          </Description>
          <Description>
            <DescriptionText>
              {t(
                'how-do-i-know-papers-modal.description2',
                `We deliver 8.5 x 11 in., 20lb, 96 bright paper that is optimized for HP printers and HP cartridges and suitable for a wide range of everyday printing needs. Our HP paper is Forest Stewardship Council® (FSC®) certified to support healthy forest growth for future generations and sourced only from responsibly managed forests.`
              )}
            </DescriptionText>
          </Description>
        </DivText>
      </Container>
    </>
  )
}
