import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { TranslatorFunctionType } from '../../../../types/localization'

import {
  Col1,
  Col2,
  Image,
  DivDescription,
  Title,
  Description,
  DivPlanInfo,
  PlanText,
  PlanSubtext,
  ShippingText,
  Price,
  ResumeButton,
  Section
} from './PlanOverview.styles'

interface PlanOverviewProps {
  t: TranslatorFunctionType
  pages: string
  papers: string
  total: string
  isPaperEnabled: boolean
  goToCart: (event: EventTarget, action: string) => void
  buttonDisabled: boolean
}

export const PlanOverview = ({
  t,
  pages,
  papers,
  total,
  isPaperEnabled,
  goToCart,
  buttonDisabled = false
}: PlanOverviewProps) => {
  const { images, planName, printerName } = useContext(ThemeContext)

  return (
    <Section>
      <Col1>
        <picture>
          <source
            srcSet={images.resume.extraLarge}
            media="(min-width: 1920px)"
          />
          <source srcSet={images.resume.larger} media="(min-width: 1440px)" />
          <source srcSet={images.resume.medium} media="(min-width: 1024px)" />
          <Image src={images.resume.extraSmall} alt="benefits-banner" />
        </picture>
      </Col1>
      <Col2>
        <DivDescription>
          <Title>
            {t(
              'product-page.plan-overview.title',
              'Order your Instant Ink Platinum plan'
            )}
          </Title>
          <Description>
            {t(
              'product-page.plan-overview.description',
              '30 day risk-free trial and free shipping'
            )}
          </Description>
        </DivDescription>
        <DivPlanInfo>
          <PlanText data-testid="dinamyc-plan-text">
            {planName} <i>{printerName}</i>
          </PlanText>
          <PlanSubtext data-testid="dinamyc-plan-subtext">
            <p>Instant Ink: {pages} pages/month</p>
            {isPaperEnabled &&
              (papers.length > 2 ? <p>Paper: Yes</p> : <p>Paper: No</p>)}
          </PlanSubtext>
          <ShippingText data-testid="dinamyc-plan-shipping">
            <p>Free Shipping</p>
            {total !== '0' && <Price>{total}/month</Price>}
          </ShippingText>
          <ResumeButton
            disabled={buttonDisabled}
            onClick={(e) => goToCart(e, 'GoToCartFloatingFooter')}
            data-testid="go-to-cart-button"
          >
            {t('product-page.plan-overview.button', 'Continue')}
          </ResumeButton>
        </DivPlanInfo>
      </Col2>
    </Section>
  )
}
