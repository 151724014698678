import React, { ReactNode } from 'react'

import { Wrapper, Logo } from './Header.styles'

export const Header = ({ children }: { children?: ReactNode }): JSX.Element => {
  return (
    <Wrapper>
      <div>
        <Logo />
      </div>
      <div>{children}</div>
    </Wrapper>
  )
}
