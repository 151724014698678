import React, { useContext } from 'react'
import { TranslatorFunctionType } from '../../../../types/localization'
import { ThemeContext } from 'styled-components'

import {
  Box,
  BoxDiv,
  PlanBox,
  BoxButton,
  Price,
  InfoContainer,
  Divider,
  PrinterModel,
  PriceBox,
  ShippingBox,
  Wrapper
} from './FloatingPlanOverview.styles'
import Button from '../../../../components/Button'

interface Props {
  t: TranslatorFunctionType
  total: string
  goToCart: (event: EventTarget, action: string) => void
  buttonDisabled: boolean
}

export const FloatingPlanOverview = ({
  t,
  total,
  goToCart,
  buttonDisabled = false
}: Props) => {
  const { planName, printerName } = useContext(ThemeContext)

  return (
    <>
      <Wrapper>
        <Box>
          <InfoContainer>
            <PlanBox>
              <p>{planName}</p>
              <Divider />
              <PrinterModel>{printerName}</PrinterModel>
            </PlanBox>
            <PriceBox>
              {total !== '0' && <Price>{total}/month</Price>}
              <ShippingBox>Free shipping</ShippingBox>
            </PriceBox>
          </InfoContainer>
          <BoxButton>
            <Button
              disabled={buttonDisabled}
              onClick={(e) => goToCart(e, 'GoToCartFloatingFooter')}
              data-testid="go-to-cart-floating"
            >
              {t('floating-plan-overview.box-text-button', 'Continue')}
            </Button>
          </BoxButton>
        </Box>
      </Wrapper>
      <BoxDiv />
    </>
  )
}
