import { useContext, useMemo } from 'react'
import { JarvisOnboardingController } from '@jarvis/react-user-onboarding/dist/components'
import { ShellContext } from '../../contexts/shell.context'

export const FullScreenSpinner = (): JSX.Element => {
  const { stack, authProvider } = useContext(ShellContext)

  const overWritedAuthProvider = useMemo(
    () => ({
      ...authProvider,
      onTokenExchangeRequired: async () => authProvider?.getAccessToken()
    }),
    [authProvider]
  )

  const controller = new JarvisOnboardingController(
    stack,
    overWritedAuthProvider,
    'en',
    'US'
  )
  return controller.renderSpinner()
}
