import { useContext } from 'react'
import { ShellContext } from '../contexts/shell.context'
import { MfePropsType } from '../types/mfeProps'

const useShell = (): MfePropsType => {
  const shell = useContext(ShellContext)

  const forceLogin = (): Promise<void> => {
    return new Promise(() => {
      window.location.href = `/login?prompt=login+consent&postLoginRedirect=/us/en/choose-plan`
    })
  }

  return {
    ...shell,
    ...{ authProvider: { ...shell.authProvider, forceLogin } },
    isLoggedIn: shell.store.state.session.isLoggedIn
  }
}

export default useShell
