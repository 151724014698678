import React, { useEffect, useState } from 'react'
import { TranslatorFunctionType } from '../../../../../types/localization'
import Camera from './assets/Camera.svg'
import ChevronDoubleLeft from './assets/Chevron-Double-Left.svg'
import Fax from './assets/Fax.svg'
import Hp1 from './assets/hp-1.png'
import Hp2 from './assets/hp-2.png'
import Hp3 from './assets/hp-3.png'
import Inbox from './assets/Inbox.svg'
import Layers from './assets/Layers.svg'
import PrintSource from './assets/Print.svg'
import RemoteDesktop from './assets/Remote-Desktop.svg'
import TopCoverUnlock from './assets/Top-Cover-Unlock.svg'
import {
  Bullet,
  Cell,
  Container,
  Icon,
  IconCell,
  LeftCell,
  Printer,
  PrinterContainer,
  TableIconHeader,
  TableStyled,
  TableTitle,
  TableTitleText
} from './Table.styles'

export interface ComparisonOption {
  title: string
  icon: React.ReactNode
  copy: boolean
  ppm: number
  capacitySheets: number
  photoTray: boolean
  lcdScreen: boolean
  usb: boolean
  fax: boolean
  feed: boolean
  unlimitedPlan: boolean
  selected?: boolean
  plan: string
}

interface Props {
  t: TranslatorFunctionType
  selectedPlan?: string
}

const buildContent = (value: string | boolean | number) => {
  if (typeof value === 'boolean') {
    const bullet = (
      <Bullet className={value ? '' : 'isDisabled'}>&#9679;</Bullet>
    )
    return <Cell key={value}>{bullet}</Cell>
  }
  return <Cell key={value}>{value}</Cell>
}

export const Table = ({ t, selectedPlan = 'versatile' }: Props) => {
  const [options, setOptions] = useState([
    {
      title: 'HP Envy',
      icon: Hp2,
      copy: true,
      ppm: 10,
      capacitySheets: 100,
      photoTray: false,
      lcdScreen: false,
      usb: false,
      fax: false,
      feed: false,
      plan: 'Essential',
      selected: selectedPlan === 'Essential'
    },
    {
      title: 'HP Envy Inspire',
      icon: Hp1,
      copy: true,
      ppm: 15,
      capacitySheets: 125,
      photoTray: true,
      lcdScreen: true,
      usb: false,
      fax: false,
      feed: false,
      plan: 'Versatile',
      selected: selectedPlan === 'Versatile'
    },
    {
      title: 'HP Officejet Pro',
      icon: Hp3,
      copy: true,
      ppm: 22,
      capacitySheets: 250,
      photoTray: false,
      lcdScreen: true,
      usb: true,
      fax: true,
      feed: true,
      plan: 'Professional',
      selected: selectedPlan === 'Professional'
    }
  ])

  useEffect(() => {
    setOptions(
      options.map((option) => ({
        ...option,
        selected: selectedPlan === option.plan
      }))
    )
  }, [selectedPlan])

  return (
    <Container>
      <TableStyled>
        <thead>
          <tr>
            <TableIconHeader />
            <th colSpan={2} />
            {options.map((option) => (
              <TableTitle key={option.title}>
                <TableTitleText>{option.title}</TableTitleText>
              </TableTitle>
            ))}
          </tr>
          <tr>
            <TableIconHeader />
            <th colSpan={2} />
            {options.map((option) => (
              <TableTitle key={option.title}>
                <PrinterContainer className={option.selected ? 'isActive' : ''}>
                  <Printer src={option.icon} />
                </PrinterContainer>
              </TableTitle>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <IconCell>
              <Icon src={PrintSource} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.print', 'Print, scan, copy')}
            </LeftCell>
            {options.map((option) => buildContent(option.copy))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={ChevronDoubleLeft} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.ppm', 'Printing speed, max ppm')}
            </LeftCell>
            {options.map((option) => buildContent(option.ppm))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={Layers} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.capacity-sheets', 'Paper input, sheets')}
            </LeftCell>
            {options.map((option) => buildContent(option.capacitySheets))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={Camera} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.photo-tray', 'Photo tray')}
            </LeftCell>
            {options.map((option) => buildContent(option.photoTray))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={RemoteDesktop} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.lcd-screen', 'LCD Touch Screen')}
            </LeftCell>
            {options.map((option) => buildContent(option.lcdScreen))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={TopCoverUnlock} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.usb', 'USB memory Card host')}
            </LeftCell>
            {options.map((option) => buildContent(option.usb))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={Fax} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.fax', 'Built-in fax')}
            </LeftCell>
            {options.map((option) => buildContent(option.fax))}
          </tr>
          <tr>
            <IconCell>
              <Icon src={Inbox} />
            </IconCell>
            <LeftCell colSpan={2}>
              {t('comparison-table.feed', 'Auto Document feeder')}
            </LeftCell>
            {options.map((option) => buildContent(option.feed))}
          </tr>
        </tbody>
      </TableStyled>
    </Container>
  )
}
