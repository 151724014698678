import useShell from './useShell'
import EnvConfig from '../configs/env'
import {
  CheckoutResponseType,
  CheckoutType,
  LineItemsType
} from '../types/checkout'
import getURLBasepath from '../helpers/getURLBasepath'
import { Global as globalConfig } from '../configs/global'

export const useCheckout = () => {
  const shell = useShell()
  const env = EnvConfig[shell.stack]
  const { enrollPath } = globalConfig

  const post = async (
    body: CheckoutType,
    anonymousCheckoutEnabled: boolean
  ): Promise<CheckoutResponseType> => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }

      if (!anonymousCheckoutEnabled) {
        const token = await shell.authProvider.getAccessToken(true)
        requestOptions.headers['Authorization'] = `Bearer ${token}`
      }

      const response = await fetch(env.CHECKOUT_URL, requestOptions)
      if (response.ok === false) {
        throw new Error(response.statusText)
      }
      return response.json()
    } catch (Error) {
      console.log(Error)
    }
  }

  const checkout = async (pages: string, anonymousCheckoutEnabled: boolean) => {
    const checkoutObject: CheckoutType = {}
    const checkoutItems: LineItemsType[] = []
    const basePath = getURLBasepath()
    const productPage = basePath + enrollPath

    if (pages) {
      checkoutItems.push({
        id: pages,
        quantity: 1,
        backProductPage: productPage
      })
    }

    checkoutObject['lineItems'] = checkoutItems
    checkoutObject['shippingConfig'] = {
      behavior: 'STANDARD'
    }
    checkoutObject['callbackOptions'] = {
      shoppingPage: {
        protocol: 'HTTPS',
        uri: productPage
      }
    }

    checkoutObject['customization'] = {
      flow: {
        enablePsfEmails: false
      }
    }
    return post(checkoutObject, anonymousCheckoutEnabled)
  }

  return { checkout }
}
