import { useContext, useMemo } from 'react'
import { JarvisOnboardingController } from '@jarvis/react-user-onboarding/dist/components'
import { ShellContext } from '../../contexts/shell.context'

type FullScreenErrorPropsType = {
  error?: unknown
  onRetryClick?: () => void
}

export const FullScreenError = ({
  error,
  onRetryClick
}: FullScreenErrorPropsType): JSX.Element => {
  const { stack, authProvider } = useContext(ShellContext)

  const overWritedAuthProvider = useMemo(
    () => ({
      ...authProvider,
      onTokenExchangeRequired: async () => authProvider.getAccessToken()
    }),
    [authProvider]
  )

  const controller = new JarvisOnboardingController(
    stack,
    overWritedAuthProvider,
    'en',
    'US'
  )
  return controller.renderError(error, onRetryClick)
}
