import styled from 'styled-components'
import breakpoints from '../../../../constants/breakpoints'
import Button from '../../../../components/Button'

export const BoxDiv = styled.div`
  position: sticky;
  bottom: 0px;
  width: 452px;
  margin: 0 40px 0 auto;

  @media screen and ${breakpoints.xsmall} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
`
export const Box = styled.div`
  display: flex;
  position: relative;
  box-shadow: 0px 0px 15px -5px;
  bottom: ${(props) => props.bottom + 'px'};
  transition: bottom 1s;
  background: #fff;
  border: none;
  height: 80px;
  border-radius: 10px;
  align-self: end;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  @media screen and ${breakpoints.xsmall} {
    padding: 10px 20px;
    width: 80%;
    height: 45px;
  }
`
export const BoxText = styled.div`
  width: 26%;
  display: flex;
  flex-direction: column;

  @media screen and ${breakpoints.medium} {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and ${breakpoints.xsmall} {
    width: 50%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`
export const BoxTextPrice = styled.p`
  color: #027aae;

  @media screen and ${breakpoints.xsmall} {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const BoxButton = styled(Button)`
  @media screen and ${breakpoints.xsmall} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 5px 5px;
  }
`
