import React from 'react'
import { TranslatorFunctionType } from '../../types/localization'

import { Container, LinkGroup } from './Footer.styles'

export const Footer = ({ t }: { t: TranslatorFunctionType }) => (
  <Container>
    <LinkGroup>
      <a href="#">{t('footer.title.link1', 'Use of Cookies')}</a>
      <span aria-hidden="true">|</span>
      <a href="#">{t('footer.title.link2', 'Privacy Statement')}</a>
    </LinkGroup>
    <p>
      {t('footer.copyright', '©Copyright 2021 HP Development Company, L.P.')}
    </p>
  </Container>
)
