import styled from 'styled-components'
import breakpoints from '../../../../constants/breakpoints'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 11;
  width: 100vw;
`

export const BoxDiv = styled.div`
  position: relative;
  bottom: 20px;
  width: 100vw;
  height: 180px;
  z-index: 10;
  @media screen and ${breakpoints.medium} {
    height: 0px;
  }
`

export const Box = styled.div`
  position: relative;
  bottom: 20px;
  box-shadow: 0px 0px 15px -5px;
  transition: bottom 1s;
  justify-content: space-between;
  background: #fff;
  border: none;
  height: 140px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 25px;
  padding-left: 25px;

  @media screen and ${breakpoints.medium} {
    margin-left: 40px;
    margin-right: 40px;
    height: 80px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media screen and ${breakpoints.xsmall} {
    padding: 0px 15px;
    height: 60px;
    margin: 0px 24px;
  }

  @media screen and ${breakpoints.xlarge} {
    max-width: 1760px;
    margin: 0 auto;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: space-between;

  @media screen and ${breakpoints.medium} {
    padding: 0px 45px 0px 25px;
  }

  @media screen and ${breakpoints.xsmall} {
    width: 100%;
  }
`

export const PlanBox = styled.div`
  height: 100%;
  display: flex;
  font-size: 20px;
  flex-direction: row;
  align-items: center;
  line-height: 28px;

  p:first-of-type {
    color: #212121 !important;
  }

  @media screen and ${breakpoints.xsmall} {
    font-size: 12px;
    line-height: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

export const PrinterModel = styled.p`
  color: #737373;
`

export const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ShippingBox = styled(PriceBox)`
  color: black;

  @media screen and ${breakpoints.xsmall} {
    display: none;
  }
`

export const Price = styled.p`
  color: #027aae;
  font-size: 24px;
  line-height: 32px;

  @media screen and ${breakpoints.xsmall} {
    font-size: 16px;
    line-height: 24px;
    margin-right: 15px;
  }
`

export const Divider = styled.div`
  height: 30%;
  border: 1px solid #929cb0;
  opacity: 0.3;
  margin: 0px 20px;

  @media screen and ${breakpoints.xsmall} {
    display: none;
  }
`

export const BoxButton = styled.div`
  min-width: 130px;
  font-size: 16px;
  line-height: 24px;

  @media screen and ${breakpoints.xsmall} {
    min-width: 106px;

    & > button {
      padding: 8px 16px;
    }
  }
`
