import styled from 'styled-components'
import breakpoints from '../../constants/breakpoints'
import { H2Styled } from '../Typography'

export const Container = styled.section`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 64px 20px;

  @media screen and ${breakpoints.medium} {
    padding: 120px 40px;
  }

  @media screen and ${breakpoints.large} {
    padding: 130px 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 180px 80px;
  }
`
export const Title = styled(H2Styled)`
  padding: 0 0 23px;

  @media screen and ${breakpoints.large} {
    padding: 0 0 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0 0 80px;
  }
`

export const Content = styled.div`
  overflow-y: auto;
  white-space: nowrap;
  overflow: hidden;
  max-height: 528px;
  position: relative;
  &.isExpanded {
    max-height: none;
  }
`
export const Disclaimers = styled.ol`
  list-style: decimal;
  white-space: normal;
  overflow: visible;
  line-height: 24px;
  font-size: 16px;
  font-family: Forma DJR Micro;

  .mb {
    margin-bottom: 10px;
  }
`

export const DisclaimersTitle = styled.h6`
  border-bottom: 1px solid #212121;
  padding: 20px 0px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-family: Forma DJR Micro;
`

export const ButtonDiv = styled.div`
  margin: 32px auto 0;
`

export const FadeOut = styled.div`
  bottom: 0em;
  width: 100%;
  height: 7em;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  &.isExpanded {
    display: none;
  }
`
