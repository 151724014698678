import styled from 'styled-components'
import { H2Styled } from '../Typography'
import breakpoints from '../../constants/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and ${breakpoints.medium} {
    flex-direction: row;
  }
`

export const DivImg = styled.div`
  height: 50%;

  @media screen and ${breakpoints.xsmall} {
    display: none;
  }

  @media screen and ${breakpoints.medium} {
    height: 100%;
    width: 50%;
    flex: 0 0 50%;
    display: block;
  }
`
export const Image = styled.img`
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;

  @media screen and ${breakpoints.medium} {
    vertical-align: bottom;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 20px;
    display: block;
  }
`
export const DivText = styled.div`
  padding: 30px 20px;
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  flex: 0 0 100%;
  overflow: auto;

  @media screen and ${breakpoints.medium} {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    width: 50%;
    padding: 0 79px 0 25px;
  }

  @media screen and ${breakpoints.large} {
    padding: 0 100px 0 43px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0 248px 0 60px;
  }
`
export const Title = styled.h2`
  color: #212121;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;

  @media screen and ${breakpoints.medium} {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and ${breakpoints.large} {
    font-size: 40px;
    line-height: 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    font-size: 64px;
    line-height: 72px;
  }
`

export const DescriptionText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #000000;
`

export const Description = styled.div`
  margin-top: 20px;
`
