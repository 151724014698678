import styled from 'styled-components'

export const sizes = {
  extraSmall: '1023px',
  medium: '1024px',
  large: '1440px',
  extraLarge: '1920px'
}

/**
 * @component
 * @visibleName Typography
 */
export const H1Styled = styled.h1`
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  font-family: 'Forma DJR Display';

  @media screen and (min-width: ${sizes.medium}) {
    font-size: 64px;
    line-height: 64px;
  }

  @media screen and (min-width: ${sizes.large}) {
    font-size: 90px;
    line-height: 90px;
  }

  @media screen and (min-width: ${sizes.extraLarge}) {
    font-size: 116px;
    line-height: 116px;
  }
`

export const H2Styled = styled.h2`
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  font-family: 'Forma DJR Display';

  @media screen and (min-width: ${sizes.medium}) {
    font-size: 48px;
    line-height: 56px;
  }

  @media screen and (min-width: ${sizes.large}) {
    font-size: 64px;
    line-height: 72px;
  }

  @media screen and (min-width: ${sizes.extraLarge}) {
    font-size: 84px;
    line-height: 92px;
  }
`

export const H3Styled = styled.h3`
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  font-family: 'Forma DJR Display';

  @media screen and (min-width: ${sizes.medium}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (min-width: ${sizes.large}) {
    font-size: 40px;
    line-height: 40px;
  }
`

export const H4Styled = styled.h4`
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Forma DJR Display';

  @media screen and (min-width: ${sizes.large}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const H5Styled = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Forma DJR Display';

  @media screen and (min-width: ${sizes.large}) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const LabelStyled = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

export const CaptionStyled = styled.caption`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`

export const NumberStyled = styled.span`
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;

  @media screen and (min-width: ${sizes.medium}) {
    font-size: 100px;
    line-height: 100px;
  }

  @media screen and (min-width: ${sizes.large}) {
    font-size: 140px;
    line-height: 140px;
  }

  @media screen and (min-width: ${sizes.extraLarge}) {
    font-size: 180px;
    line-height: 180px;
  }
`

export const Link = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #027aae;
  text-decoration: none;
  cursor: pointer;
  font-size: 100%;
`
