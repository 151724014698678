import React from 'react'

import useAnalytics from '../../../../hooks/useAnalytics'
import { TranslatorFunctionType } from '../../../../types/localization'
import Button from '../../../../components/Button'
import { Global as globalConfig } from '../../../../configs/global'

import {
  Container,
  DivText,
  DivImg,
  Image,
  Title,
  Description
} from './ChooseYourPlan.styles'

import PrintersImgXS from './Assets/Images/printers-xs.jpg'
import PrintersImgM from './Assets/Images/printers-m.jpg'
import PrintersImgL from './Assets/Images/printers-l.jpg'
import PrintersImgXL from './Assets/Images/printers-xl.jpg'

export const ChooseYourPlan = ({ t }: { t: TranslatorFunctionType }) => {
  const { publishEventPromise, events } = useAnalytics()
  const { enrollPath } = globalConfig

  const ChoosePlanClick = () => {
    publishEventPromise(events.ChooseYourPlanChoosePlan()).then(() => {
      window.location.href = enrollPath
    })
  }

  return (
    <Container>
      <DivImg>
        <Image
          src={PrintersImgXS}
          srcSet={`
            ${PrintersImgM} 1024w,
            ${PrintersImgL} 1440w,
            ${PrintersImgXL} 1920w
          `}
        />
      </DivImg>
      <DivText>
        <Description>
          {t('choose-your-plan.description', 'Printer. Ink. Paper. Support.')}
        </Description>
        <Title>
          {t(
            'choose-your-plan.title',
            'One complete subscription, everything you need¹'
          )}
        </Title>
        <div>
          <Button
            onClick={ChoosePlanClick}
            data-testid="choose-your-plan-button"
          >
            {t('choose-your-plan.button', 'Get Started')}
          </Button>
        </div>
      </DivText>
    </Container>
  )
}
