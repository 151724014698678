// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/FormaDJRDisplay-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/FormaDJRDisplay-Regular.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/FormaDJRMicro-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "../assets/fonts/FormaDJRMicro-Regular.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'Forma DJR Display';font-weight:400;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-family:'Forma DJR Micro';font-weight:400;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}html,body{font-family:'Forma DJR Micro'}h1,h2,h3,h4,h5,h6{font-family:'Forma DJR Display'}\n", "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA,WAAW,+BAA+B,CAAC,eAAe,CAAC,iBAAiB,CAAC,kHAA2I,CAAC,WAAW,6BAA6B,CAAC,eAAe,CAAC,iBAAiB,CAAC,kHAAuI,CAAC,UAAU,6BAA6B,CAAC,kBAAkB,+BAA+B","sourcesContent":["@font-face{font-family:'Forma DJR Display';font-weight:400;font-display:swap;src:url(\"../assets/fonts/FormaDJRDisplay-Regular.woff2\") format(\"woff2\"),url(\"../assets/fonts/FormaDJRDisplay-Regular.woff\") format(\"woff\")}@font-face{font-family:'Forma DJR Micro';font-weight:400;font-display:swap;src:url(\"../assets/fonts/FormaDJRMicro-Regular.woff2\") format(\"woff2\"),url(\"../assets/fonts/FormaDJRMicro-Regular.woff\") format(\"woff\")}html,body{font-family:'Forma DJR Micro'}h1,h2,h3,h4,h5,h6{font-family:'Forma DJR Display'}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
