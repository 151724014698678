import React from 'react'
import {
  Input,
  Wrapper,
  Content,
  Price,
  Label,
  Description
} from './OptionButton.styles'
interface Props {
  checked: boolean
  name: string
  value: string
  description?: string
  price?: string
  label: string
  testId?: string
  onChangeValue?: (event: EventTarget) => void
}

export const OptionButton = ({
  name,
  value,
  checked,
  description,
  price,
  label,
  testId,
  onChangeValue
}: Props) => {
  return (
    <Wrapper>
      <Input
        type="radio"
        onChange={onChangeValue}
        data-testid={testId}
        name={name}
        value={value}
        checked={checked}
      />
      <Content>
        <div>
          <Description>{description}</Description>
          {label && <Label>{label}</Label>}
        </div>
        {price && <Price>{price}/month</Price>}
      </Content>
    </Wrapper>
  )
}
