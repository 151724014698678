import styled from 'styled-components'
import breakpoints from '../../../../constants/breakpoints'
import Button from '../../../../components/Button'
import {
  Image as ImageCommon,
  Col2 as Col2Common,
  Section as SectionCommon,
  TitlePlanOverview as TitleCommon,
  DivDescription as DivDescriptionCommon,
  Description as DescriptionCommon
} from '../Common/Styles/ProductPage.styles'
import { H3Styled } from '../../../../components/Typography'

export const Section = styled(SectionCommon)``

export const Col1 = styled.div`
  @media screen and (orientation: landscape) and ${breakpoints.medium} {
    height: 33%;
    display: flex;
    align-items: center;

    @media screen and ${breakpoints.medium} {
      height: 100%;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`

export const Col2 = styled(Col2Common)`
  text-align: center;

  @media screen and ${breakpoints.medium} {
    text-align: left;
    max-width: clamp(381px, 34%, 530px);
  }
`

export const Image = styled(ImageCommon)``
export const Title = styled(TitleCommon)``
export const Description = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and ${breakpoints.large} {
    font-size: 24px;
    line-height: 32px;
  }
`

export const DivPlanInfo = styled.div`
  @media screen and ${breakpoints.medium} {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and ${breakpoints.xsmall} {
    font-size: 12px;
    line-height: 20px;
  }

  & > p {
    display: block;
    position: relative;

    & > i {
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
      margin-left: 16px;
    }
  }

  & > h3 {
    display: block;
    position: relative;

    & > i {
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
      margin-left: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media screen and ${breakpoints.large} {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
`

export const PlanText = styled(H3Styled)``

export const PlanSubtext = styled.p`
  padding: 16px 0px;

  @media screen and ${breakpoints.medium} {
    padding: 0px 0px 16px 0px;
  }

  & > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media screen and ${breakpoints.xsmall} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const ShippingText = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    font-size: 18px;
    line-height: 26px;
  }

  @media screen and ${breakpoints.medium} {
    flex-direction: row;
  }
`

export const Price = styled.p`
  margin-top: 24px;
  color: #027aae;
  @media screen and ${breakpoints.medium} {
    margin-top: 0px;
  }
`

export const ResumeButton = styled(Button)`
  width: auto;
  margin: 0 auto;
`

export const DivDescription = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  text-align: center;

  @media screen and ${breakpoints.medium} {
    row-gap: 0px;
    text-align: left;
    margin-bottom: 100px;
  }
`
