import styled from 'styled-components'
import breakpoints from '../../../../constants/breakpoints'
import { H2Styled } from '../../../../components/Typography'

export const Container = styled.section`
  background: #f8f8f8;
  padding: 30px 0;

  @media ${breakpoints.medium} {
    min-height: 200vh;
    padding: 60px 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  box-sizing: border-box;

  @media ${breakpoints.medium} {
    flex-direction: row;
    align-items: center;
    height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
    padding: 60px 40px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 44px;
  object-fit: cover;
  object-position: center;

  @media ${breakpoints.medium} {
    margin-bottom: 0px;
  }
`

export const Col = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${(props) => (props.overflowHidden ? 'hidden' : 'initial')};
`

export const List = styled.ol`
  opacity: 0.99;
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: step;

  @media ${breakpoints.medium} {
    transform: translateY(28vh);
    margin-left: 6vw;
  }
`

export const Step = styled.li`
  padding: 0;
  color: #212121;
  margin-bottom: 45px;
  position: relative;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    counter-increment: step;
    content: counter(step);
    font-size: 60px;
    line-height: 1.2;
    display: inline-block;
    color: inherit;
    font-family: 'Forma DJR Micro';
  }

  @media ${breakpoints.medium} {
    padding-left: 84px;
    margin-bottom: 8.6vh;
    max-width: 580px;

    &:not(:first-child) {
      opacity: 0.15;
    }

    &:before {
      font-size: 100px;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media ${breakpoints.large} {
    margin-bottom: 15vh;
    padding-left: 108px;

    &:before {
      font-size: 140px;
    }
  }

  @media ${breakpoints.xlarge} {
    padding-left: 128px;
    &:before {
      font-size: 180px;
    }
  }
`

export const Title = styled(H2Styled)`
  color: inherit;
  margin-bottom: 16px;
`

export const Description = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: inherit;

  @media ${breakpoints.large} {
    font-size: 20px;
  }
`
