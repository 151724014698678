import PrinterImgXS from '../images/versatile/printer-xs.png'
import PrinterImgM from '../images/versatile/printer-m.png'
import PrinterImgL from '../images/versatile/printer-l.png'
import PrinterImgXL from '../images/versatile/printer-xl.png'

import ResumeImgXS from '../images/versatile/resume-xs.png'
import ResumeImgM from '../images/versatile/resume-m.png'
import ResumeImgL from '../images/versatile/resume-l.png'
import ResumeImgXL from '../images/versatile/resume-xl.png'

import BackgroundImgXS from '../images/versatile/background-xs.png'
import BackgroundImgM from '../images/versatile/background-m.png'
import BackgroundImgL from '../images/versatile/background-l.png'
import BackgroundImgXL from '../images/versatile/background-xl.png'

export default {
  planName: 'Versatile',
  description: 'Great for families with kids and home learners.',
  printerName: 'HP Envy Inspire',
  colors: {
    primary: '#737373',
    secondary: '#027AAE',
    background: '#EDE9E4',
    borderOptions: '#ADADAD',
    text: '#212121'
  },
  images: {
    printer: {
      extraSmall: PrinterImgXS,
      medium: PrinterImgM,
      larger: PrinterImgL,
      extraLarge: PrinterImgXL
    },
    resume: {
      extraSmall: ResumeImgXS,
      medium: ResumeImgM,
      larger: ResumeImgL,
      extraLarge: ResumeImgXL
    },
    background: {
      extraSmall: BackgroundImgXS,
      medium: BackgroundImgM,
      larger: BackgroundImgL,
      extraLarge: BackgroundImgXL
    }
  }
}
