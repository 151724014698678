import styled from 'styled-components'
import ChevronDown from './Assets/images/chevron_down.svg'

export const AccordionTitleContainer = styled.div`
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 12px;

  * {
    font-family: Forma DJR Micro;
  }
`

export const AccordionTitle = styled.button`
  font-family: Forma DJR Micro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  border: none;
  background: none;
  text-align: left;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  &:after {
    content: '';
    right: 16px;
    width: 32px;
    height: 16px;
    background-color: transparent;
    background-image: url(${ChevronDown});
    opacity: 1;
    background-size: 1rem 1rem;
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform 0.5s ease;
  }

  &.isExpanded:after {
    transform: rotate(-180deg);
  }
`

export const AccordionBody = styled.div`
  font-family: Forma DJR Micro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #212121;
  transition: 0.5s ease;
  overflow: hidden;
  opacity: 0;
  height: 0;

  &.isExpanded {
    height: auto;
    opacity: 1;
  }
`

export const AccordionContent = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;

  ul {
    margin-left: 15px;
    list-style: initial;
  }
`

export const Separator = styled.hr`
  width: 100%;
  margin: 0;
  border-top: 1px solid #212121;
  opacity: 10%;
`
