import styled from 'styled-components'

export const ModalWrapper = styled.div`
  display: ${(props) => (props.isOpen ? 'grid' : 'none')};

  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  grid-area: 1/-1;
  place-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const Background = styled.div`
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
`
export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
  max-height: 90%;
  min-width: 20%;
  max-width: 96%;
  overflow: hidden;
`
export const CloseModalButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  padding: 0;
  z-index: 10;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`
