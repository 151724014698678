import React from 'react'

import { TranslatorFunctionType } from '../../../../types/localization'

import {
  Container,
  DivText,
  DivImg,
  Image,
  Title,
  Description
} from './PrinterUpgrades.styles'

import ImgXS from './Assets/Images/Img-small.jpg'
import ImgM from './Assets/Images/Img-medium.jpg'
import ImgL from './Assets/Images/Img-larger.jpg'
import ImgXL from './Assets/Images/Img-xlarger.jpg'

export const PrinterUpgrades = ({ t }: { t: TranslatorFunctionType }) => {
  return (
    <Container>
      <DivImg>
        <picture>
          <source srcSet={ImgXL} media="(min-width: 1920px)" />
          <source srcSet={ImgL} media="(min-width: 1440px)" />
          <source srcSet={ImgM} media="(min-width: 1024px)" />
          <Image src={ImgXS} />
        </picture>
      </DivImg>
      <DivText>
        <Title>
          {t('printer-upgrades.title', 'Stay up-to-date with printer upgrades')}
        </Title>
        <Description>
          {t(
            'printer-upgrades.description',
            `With Platinum, you're eligible every two years to select a new printer from HP's latest models. Your subscription includes your printer and shipping at no additional charge.`
          )}
        </Description>
      </DivText>
    </Container>
  )
}
