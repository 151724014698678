import styled from 'styled-components'
import { H2Styled, H5Styled } from '../../../../components/Typography'
import breakpoints from '../../../../constants/breakpoints'

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`
export const Container = styled.section`
  height: 731px;

  @media screen and ${breakpoints.medium} {
    height: 100vh;
    position: relative;
    display: flex;
  }
`

export const DivImg = styled.div`
  height: 50%;
  overflow: hidden;
  margin: 0px 20px 0px;
  @media screen and ${breakpoints.medium} {
    height: 100%;
    width: 50%;
    margin: 0px 40px 0px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin: 0px 87px 0px;
  }
`

export const DivText = styled.div`
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and ${breakpoints.medium} {
    padding: 0px 40px;
    width: 50%;
    height: 100%;
  }

  @media screen and ${breakpoints.large} {
    padding: 0px 71px 0px 77px;
  }
`

export const Title = styled(H2Styled)`
  margin-bottom: 16px;

  @media screen and ${breakpoints.medium} {
    margin-bottom: 24px;
  }

  @media screen and ${breakpoints.large} {
    margin-bottom: 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin-bottom: 52px;
  }
`

export const Description = styled(H5Styled)`
  line-height: 28px;
  margin-bottom: 5px;
  text-transform: uppercase;
`
