import styled from 'styled-components'
import breakpoints from '../../../../../constants/breakpoints'
import { H1Styled, H5Styled, sizes } from '../../../../../components/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 95%;
  width: 1340px;
`

export const Icon = styled.img``

export const Printer = styled.img`
  width: 31px;
  height: 23px;

  @media screen and (min-width: ${sizes.medium}) {
    width: 57px;
    height: 44px;
  }
`

export const Bullet = styled.span`
  color: #027aae;

  &.isDisabled {
    color: rgba(2, 122, 174, 0.1);
  }
`

export const PrinterContainer = styled.div`
  border-radius: 50%;
  padding: 0px;
  width: 40px;
  height: 40px;
  margin: auto;
  box-sizing: border-box;
  display: grid;
  place-items: center;
  &.isActive {
    background: #027aae;
  }

  @media screen and (min-width: ${sizes.medium}) {
    width: 70px;
    height: 70px;
  }
`

export const TableStyled = styled.table`
  background: #ffffff;
  color: #027aae;
  border-spacing: 0 34px;
  margin: 64px 24px;

  @media screen and ${breakpoints.medium} {
    border-collapse: separate;
    width: 100%;

    & thead {
      position: sticky;
      top: 46px;
      }
    }
  }

  @media screen and ${breakpoints.xsmall} {
    & thead {
      background-color: #ffffff;
      position: sticky;
      top: 0px;
    }

    & tbody {
      & td {
        padding: 20px 0px;
      }
    }
  }

  @media screen and (min-width: ${sizes.medium}) {
    border-spacing: 0 20px;
  }
`

export const Cell = styled.td`
  text-align: center;
`

export const TableTitle = styled.th`
  background: #ffffff;
  font-family: Forma DJR Micro;
  color: #000000;
`

export const TableIconHeader = styled.th`
  display: none;

  @media screen and (min-width: ${sizes.medium}) {
    display: inline;
  }
`

export const TableTitleText = styled(H5Styled)`
  margin-bottom: 18px;
  font-size: 12px;
  line-height: 16px;

  @media screen and (min-width: ${sizes.medium}) {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 38px;
  }
`

export const LeftCell = styled.td`
  font-family: Forma DJR Micro;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 5px;
`

export const IconCell = styled.td`
  text-align: center;
  display: none;

  @media screen and (min-width: ${sizes.medium}) {
    display: inline;
  }
`
