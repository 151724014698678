import styled from 'styled-components'
import { LabelStyled, H1Styled } from '../../../../components/Typography'
import breakpoints from '../../../../constants/breakpoints'

export const Container = styled.section`
  height: 981px;
  position: relative;
  overflow: hidden;
  @media screen and ${breakpoints.medium} {
    height: 100vh;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

export const DivImg = styled.div`
  height: 60%;
  @media screen and ${breakpoints.medium} {
    height: 100%;
  }
`

export const DivText = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 39px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and ${breakpoints.medium} {
    align-items: center;
    justify-content: end;
    padding: 36px 20px;
  }

  @media screen and ${breakpoints.large} {
    padding: 50px 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 50px 80px;
  }
`

export const Content = styled.article`
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and ${breakpoints.medium} {
    height: 100%;
    align-items: center;
  }
`

export const Title = styled.div`
  color: #fff;
  text-transform: uppercase;
  padding: 20px 20px 53px 20px;
  width: 100%;
  box-sizing: border-box;

  @media screen and ${breakpoints.medium} {
    padding: 20px 20px 0px 20px;
  }

  @media screen and ${breakpoints.large} {
    padding: 0px 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0px 80px;
  }
`

export const TextTitle = styled(LabelStyled)`
  text-align: left !important;
  letter-spacing: 0.1em !important;
`

export const TextSubTitle = styled(H1Styled)`
  text-align: left;
  text-transform: capitalize !important;
  letter-spacing: 0.02em;
  @media screen and ${breakpoints.medium} {
    width: 60%;
    margin-top: 16px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin-top: 32px;
  }
`

export const Icons = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 18px;
  @media screen and ${breakpoints.medium} {
    flex-direction: row;
    font-size: 16px;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
  }

  @media screen and ${breakpoints.large} {
    font-size: 20px;
  }
`

export const Icon = styled.li`
  display: flex !important;
  margin-bottom: 13px;
  & svg {
    margin-right: 10px;
  }
  @media screen and ${breakpoints.medium} {
    margin-bottom: 0px;
    width: 164px;
    flex-direction: row !important;
    align-items: flex-start;
    & svg {
      color: #fff !important;
      align-self: flex-start;
    }
    & p {
      align-self: flex-start;
    }
  }
  @media screen and ${breakpoints.large} {
    width: 216px;
  }
`

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 16px;
  row-gap: 12px;
  column-gap: 25px;
  @media screen and ${breakpoints.medium} {
    flex-direction: row;
    padding: 0px 20px;
  }

  @media screen and ${breakpoints.large} {
    padding: 0px 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0px 80px;
  }
`

export const Separator = styled.hr`
  margin: 16px 0 0;
  border-top: 1px solid #fff;
  border-left: none;
  border-right: none;
  border-bottom: none;

  @media screen and ${breakpoints.medium} {
    margin: 24px 0 0;
  }

  @media screen and ${breakpoints.large} {
    padding: 0 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0 84px;
  }
`

export const DivContent = styled.div`
  position: absolute;
  bottom: 6%;
  width: 100%;
  @media screen and ${breakpoints.medium} {
    bottom: 27%;
  }

  @media screen and ${breakpoints.large} {
    bottom: 11%;
  }

  @media screen and ${breakpoints.xlarge} {
    bottom: 25%;
  }
`
