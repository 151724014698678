import React from 'react'
import { OptionButton } from '../../../../components/OptionButton/OptionButton'

import PagesXL from '../../assets/images/pages-xl.png'
import PagesL from '../../assets/images/pages-l.png'
import PagesM from '../../assets/images/pages-m.png'
import PagesXS from '../../assets/images/pages-xs.png'

import {
  Image,
  Col1,
  Col2,
  Section,
  Title,
  Link,
  DivDescription,
  Wrapper,
  OptionGroup
} from '../Common/Styles/ProductPage.styles'
import { TranslatorFunctionType } from '../../../../types/localization'
import useAnalytics from '../../../../hooks/useAnalytics'
import { CatalogGatewayParsedContentType } from '../../../../types/catalogGatewayParsed'

interface Props {
  t: TranslatorFunctionType
  handlePagesChange: (event: EventTarget) => void
  openHowDoIKnowModal: () => void
  pages: string
  options: CatalogGatewayParsedContentType[]
}

export const ChoosePages = ({
  t,
  handlePagesChange,
  openHowDoIKnowModal,
  pages,
  options
}: Props) => {
  const { events, publishEvent } = useAnalytics()

  const openModal = () => {
    publishEvent(events.ClickHowManyPages())
    openHowDoIKnowModal()
  }

  if (!options) {
    return null
  }

  return (
    <Section>
      <Col1>
        <picture>
          <source srcSet={PagesXL} media="(min-width: 1920px)" />
          <source srcSet={PagesL} media="(min-width: 1440px)" />
          <source srcSet={PagesM} media="(min-width: 1024px)" />
          <Image src={PagesXS} alt="benefits-banner" />
        </picture>
      </Col1>
      <Col2>
        <DivDescription>
          <Title>
            {t(
              'product-page.pages.title',
              'Select number of pages to print per month'
            )}
          </Title>
          <Link
            data-testid={'how-do-i-know-how-many-pages'}
            onClick={openModal}
          >
            {t('product-page.pages.link', 'How many pages will I need?')}
          </Link>
        </DivDescription>
        <Wrapper>
          <OptionGroup>
            {options.map((option: CatalogGatewayParsedContentType, index) => (
              <OptionButton
                key={index}
                name="pages"
                checked={pages === option.id}
                value={option.id}
                description={`${option.pages} pages`}
                price={option.formattedPrice}
                label={''}
                testId={`papers-quantity-button-${index}`}
                onChangeValue={handlePagesChange}
              />
            ))}
          </OptionGroup>
        </Wrapper>
      </Col2>
    </Section>
  )
}
