import styled from 'styled-components'
import LogoSVG from './LogoSvg'
import theme from '../../constants/theme'
import breakpoints from '../../constants/breakpoints'

export const Wrapper = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px ${theme.gutter.xs};

  @media ${breakpoints.medium} {
    padding: 24px ${theme.gutter.md};
  }

  @media ${breakpoints.large} {
    padding: 32px ${theme.gutter.md};
  }
`

export const Logo = styled(LogoSVG)`
  width: 6.875rem;
  color: ${theme.color.white};

  #svg-hp-circle {
    color: ${theme.color.white};
  }
`
