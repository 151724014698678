import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { CdmEvent } from '../../constants/ui_events'

import useAnalytics from '../../hooks/useAnalytics'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

interface Props {
  children: ReactElement
  observerOptions?: IntersectionObserverInit
  onVisible: () => void
  displayedEvent?: () => CdmEvent
}

export const VisibilityObserver = ({
  children,
  observerOptions,
  onVisible,
  displayedEvent
}: Props) => {
  const observedRef = useRef()
  const { publishEvent } = useAnalytics()
  const [displayed, setDisplayed] = useState(false)
  const { visible } = useIntersectionObserver(observedRef, observerOptions)

  useEffect(() => {
    if (visible === true) {
      onVisible()

      if (displayedEvent && !displayed) {
        publishEvent(displayedEvent())
        setDisplayed(true)
      }
    }
  }, [visible])

  return <div ref={observedRef}>{children}</div>
}
