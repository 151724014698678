import useSWR from 'swr'
import EnvConfig from '../configs/env'
import useShell from '../hooks/useShell'
import catalogGateway from '../adapters/catalogGateway'
import { LocalizationType } from '../types/localization'
import { CatalogGatewayParsedType } from '../types/catalogGatewayParsed'

export const useCatalog = <Data = CatalogGatewayParsedType, Error = any>(
  localization: LocalizationType
) => {
  const shell = useShell()
  const env = EnvConfig[shell.stack]

  const { data, error } = useSWR(env.CATALOG_URL, async (url: string) => {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Error fetching catalog data.')
    }
    const responseJson = await response.json()
    return catalogGateway(responseJson, localization.locale!)
  })

  return { data, error, isLoading: !error && !data }
}
