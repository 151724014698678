import {
  CatalogGatewayContentType,
  CatalogGatewayType
} from '../types/catalogGateway'
import {
  CatalogGatewayParsedContentType,
  CatalogGatewayParsedType
} from '../types/catalogGatewayParsed'

const buildObject = (data: CatalogGatewayContentType[], locale: string) => {
  return data
    .sort(
      (
        contentA: CatalogGatewayContentType,
        contentB: CatalogGatewayContentType
      ) =>
        Number(contentA.mandatoryPricePlan.pagePerMonth) -
        Number(contentB.mandatoryPricePlan.pagePerMonth)
    )
    .map((content: CatalogGatewayContentType) => {
      const object: CatalogGatewayParsedContentType = {}
      object.id = content.mandatoryPricePlan.id
      object.pages = content.mandatoryPricePlan.pagePerMonth
      object.price = Number(content.mandatoryPricePlan.price)
      object.formattedPrice = Number(
        content.mandatoryPricePlan.price
      ).toLocaleString(locale, {
        style: 'currency',
        currency: content.mandatoryPricePlan.currency,
        minimumFractionDigits: 2
      })
      object.currency = content.mandatoryPricePlan.currency

      if (content.optionalPricePlans.content.length > 0) {
        object.paperAddon = {
          id: content.optionalPricePlans.content[0].id,
          price: Number(content.optionalPricePlans.content[0].price),
          formattedPrice: Number(
            content.optionalPricePlans.content[0].price
          ).toLocaleString('locale', {
            style: 'currency',
            currency: content.optionalPricePlans.content[0].currency,
            minimumFractionDigits: 2
          }),
          currency: content.optionalPricePlans.content[0].currency
        }
      }
      return object
    })
}

export default (
  props: CatalogGatewayType,
  locale: string
): CatalogGatewayParsedType => {
  const parsed: CatalogGatewayParsedType = {}

  const printers = props.contents
    .map((content) => content.productFamily.offerGroup)
    .filter((printer, index, arr) => arr.indexOf(printer) === index)

  printers.forEach((printer) => {
    const data: CatalogGatewayContentType[] = props.contents.filter(
      (content: CatalogGatewayContentType) =>
        content.productFamily.offerGroup === printer
    )
    parsed[printer] = buildObject(data, locale)
  })
  return parsed
}
