import React, { useState, useEffect } from 'react'

import useAnalytics from '../../hooks/useAnalytics'
import { TranslatorFunctionType } from '../../types/localization'

import { Accordion, AccordionContentType } from '../Accordion/Accordion'
import { Container, Title, ContentBody, FAQLink } from './FAQ.styles'
import Button from '../Button'
import defaultContent from './content.json'

export const FAQs = ({ t }: { t: TranslatorFunctionType }) => {
  const [expanded, setExpanded] = useState(false)
  const [accordionData, setData] = useState([])
  const [accordionExtraData, setExtraData] = useState([])
  const { publishEvent, events } = useAnalytics()
  const contentLength = Object.keys(defaultContent.content).length
  const limit = 7

  const ViewMoreClick = () => {
    setExpanded(!expanded)
    publishEvent(events.FAQsViewMore())
  }

  useEffect(() => {
    const data: AccordionContentType[] = []
    const extraData: AccordionContentType[] = []

    for (let key = 0; key < contentLength; key++) {
      const content: AccordionContentType = {
        title: t(
          `faqs.content.row-${key}.title`,
          defaultContent.content[`row-${key}`].title
        ),
        content: t(
          `faqs.content.row-${key}.description`,
          defaultContent.content[`row-${key}`].description
        ),
        onClickTitle: (accordianAction) =>
          AccordionTitleClick(
            accordianAction,
            defaultContent.content[`row-${key}`].title
          )
      }

      if (key < limit) {
        data.push(content)
      } else {
        extraData.push(content)
      }
    }

    setData(data)
    setExtraData(extraData)
  }, [])

  const AccordionTitleClick = (accordianAction, question) => {
    publishEvent(
      events.FAQsControlAccordianExpanded(
        accordianAction,
        question.substring(0, 249)
      )
    )
  }

  return (
    <Container>
      <Title>{t('faqs.header', defaultContent.header)}</Title>
      {accordionData.map((item: AccordionContentType, index) => (
        <Accordion
          key={'faq' + index}
          title={item.title}
          content={item.content}
          onClickTitle={item.onClickTitle}
          testId={'faqs-' + index}
        />
      ))}
      <ContentBody aria-expanded={expanded} data-testid="faqs-expandable-div">
        {accordionExtraData.map((item: AccordionContentType, index) => (
          <Accordion
            key={'extraFaq' + index}
            title={item.title}
            content={item.content}
            onClickTitle={item.onClickTitle}
          />
        ))}
      </ContentBody>
      <FAQLink>
        <Button
          onClick={ViewMoreClick}
          outlined
          data-testid="faqs-view-more-button"
        >
          View {expanded ? 'less' : 'more'}
        </Button>
      </FAQLink>
    </Container>
  )
}
