import PrinterImgXS from '../images/essential/printer-xs.png'
import PrinterImgM from '../images/essential/printer-m.png'
import PrinterImgL from '../images/essential/printer-l.png'
import PrinterImgXL from '../images/essential/printer-xl.png'

import ResumeImgXS from '../images/essential/resume-xs.png'
import ResumeImgM from '../images/essential/resume-m.png'
import ResumeImgL from '../images/essential/resume-l.png'
import ResumeImgXL from '../images/essential/resume-xl.png'

import BackgroundImgXS from '../images/essential/background-xs.png'
import BackgroundImgM from '../images/essential/background-m.png'
import BackgroundImgL from '../images/essential/background-l.png'
import BackgroundImgXL from '../images/essential/background-xl.png'

export default {
  planName: 'Essential',
  description: 'Good for general printing and scanning needs.',
  printerName: 'HP Envy',
  colors: {
    primary: '#737373',
    secondary: '#027AAE',
    background: '#F3F3F3',
    borderOptions: '#ADADAD',
    text: '#212121'
  },
  images: {
    printer: {
      extraSmall: PrinterImgXS,
      medium: PrinterImgM,
      larger: PrinterImgL,
      extraLarge: PrinterImgXL
    },
    resume: {
      extraSmall: ResumeImgXS,
      medium: ResumeImgM,
      larger: ResumeImgL,
      extraLarge: ResumeImgXL
    },
    background: {
      extraSmall: BackgroundImgXS,
      medium: BackgroundImgM,
      larger: BackgroundImgL,
      extraLarge: BackgroundImgXL
    }
  }
}
