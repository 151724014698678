interface CdmEventDefaults {
  readonly screenPath: string
  readonly activity: string
  readonly version: string
}

export interface CdmEvent extends CdmEventDefaults {
  readonly screenPath: string
  readonly screenName: 'LandingPage' | 'ConfigurationPage'
  readonly screenMode: string
  readonly action:
    | 'ScreenDisplayed'
    | 'ControlButtonClicked'
    | 'ControlAccordianExpanded'
    | 'ControlAccordianCollapsed'
    | 'ControlHyperLinkClicked'
  readonly controlName?: string
  readonly controlDetail?: string
  readonly actionAuxParams?: string
}

const parseAuxParams = (params: Record<string, string>): string => {
  return Object.entries(params).reduce((acc, [key, value], index) => {
    if (index > 0) acc = `${acc}&`
    acc += `${key}=${value}`
    return acc
  }, '')
}

const EVENT_DEFAULTS: CdmEventDefaults = {
  screenPath: '/ReactPaasLandingPage/',
  activity: 'PaasEnrollment-v01',
  version: '1.3.0'
}

export type IEvents = {
  LandingPageDisplayed: () => CdmEvent
  ChooseYourPlanDisplayed: () => CdmEvent
  SubscriptionDisplayed: () => CdmEvent
  BenefitsDisplayed: () => CdmEvent
  PrinterUpgradesDisplayed: () => CdmEvent
  HowItWorksDisplayed: () => CdmEvent
  RecyclingDisplayed: () => CdmEvent
  FAQsDisplayed: () => CdmEvent
  DisclaimersDisplayed: () => CdmEvent
  TopGetStarted: () => CdmEvent
  ChooseYourPlanChoosePlan: () => CdmEvent
  FAQsControlAccordianExpanded: (
    accordianAction: 'ControlAccordianExpanded' | 'ControlAccordianCollapsed',
    question: string
  ) => CdmEvent
  FAQsViewMore: () => CdmEvent
  DisclaimersViewMore: () => CdmEvent
  ChoosePlanFloatingFooter: ({ screenMode: string }) => CdmEvent
  ConfigurationPrinterGoToCart: (
    actionAuxParams: Record<string, string>,
    screenMode: string,
    controlName?: string
  ) => CdmEvent
  ChoosePrinterDisplayed: () => CdmEvent
  ChoosePlanDisplayed: () => CdmEvent
  ChoosePaperDisplayed: () => CdmEvent
  OrderDisplayed: () => CdmEvent
  ClickComparisonTable: () => CdmEvent
  ClickHowManyPages: () => CdmEvent
  ClickNeedPaper: () => CdmEvent
  ClickLogin: () => CdmEvent
}

export const Events: IEvents = {
  LandingPageDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Top',
    action: 'ScreenDisplayed'
  }),

  TopGetStarted: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Top',
    action: 'ControlButtonClicked',
    controlName: 'GetStarted'
  }),

  ChooseYourPlanChoosePlan: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'SimplestWayToPrint',
    action: 'ControlButtonClicked',
    controlName: 'ChoosePlan'
  }),

  ChoosePlanFloatingFooter: ({ screenMode }) => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: screenMode,
    action: 'ControlButtonClicked',
    controlName: 'ChoosePlanFloatingFooter'
  }),

  ChooseYourPlanDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'SimplestWayToPrint',
    action: 'ScreenDisplayed'
  }),

  SubscriptionDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'OptionForEveryone',
    action: 'ScreenDisplayed'
  }),

  BenefitsDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'ConvenientFlexibleSustainable',
    action: 'ScreenDisplayed'
  }),

  PrinterUpgradesDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'PrinterUpgrades',
    action: 'ScreenDisplayed'
  }),

  HowItWorksDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'ChooseActivatePrint',
    action: 'ScreenDisplayed'
  }),

  RecyclingDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'ProtectThePlanet',
    action: 'ScreenDisplayed'
  }),

  FAQsDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Faqs',
    action: 'ScreenDisplayed'
  }),

  FAQsControlAccordianExpanded: (accordianAction, question) => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Faqs',
    action: accordianAction,
    controlName: 'Faq',
    controlDetail: question
  }),

  FAQsViewMore: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Faqs',
    action: 'ControlButtonClicked',
    controlName: 'ViewMore'
  }),

  DisclaimersDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Disclaimers',
    action: 'ScreenDisplayed'
  }),

  DisclaimersViewMore: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'LandingPage',
    screenMode: 'Disclaimers',
    action: 'ControlButtonClicked',
    controlName: 'ViewMore'
  }),

  ConfigurationPrinterGoToCart: (
    actionAuxParams: Record<string, string>,
    screenMode: string,
    controlName?: string
  ) => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: screenMode,
    action: 'ControlButtonClicked',
    actionAuxParams: parseAuxParams(actionAuxParams),
    controlName: controlName
  }),

  ChoosePrinterDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePrinter',
    action: 'ScreenDisplayed'
  }),

  ChoosePlanDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePlan',
    action: 'ScreenDisplayed'
  }),

  ChoosePaperDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePaper',
    action: 'ScreenDisplayed'
  }),

  OrderDisplayed: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'Order',
    action: 'ScreenDisplayed'
  }),

  ClickComparisonTable: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePrinter',
    controlName: 'ComparisonTable',
    action: 'ControlHyperLinkClicked'
  }),

  ClickHowManyPages: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePlan',
    controlName: 'HowManyPages',
    action: 'ControlHyperLinkClicked'
  }),

  ClickNeedPaper: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePaper',
    controlName: 'NeedPaper',
    action: 'ControlHyperLinkClicked'
  }),

  ClickLogin: () => ({
    ...EVENT_DEFAULTS,
    screenName: 'ConfigurationPage',
    screenMode: 'ChoosePrinter',
    action: 'ControlButtonClicked',
    controlName: 'Login'
  })
}
