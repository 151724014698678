import React from 'react'

import {
  Container,
  Description,
  Content,
  DivText,
  DivImg,
  Title,
  Image
} from './Subscription.styles'

import { TranslatorFunctionType } from '../../../../types/localization'
import BannerImgXS from './assets/banner-xs.png'
import BannerImgM from './assets/banner-m.png'
import BannerImgL from './assets/banner-l.png'
import BannerImgXL from './assets/banner-xl.png'

export const Subscription = ({ t }: { t: TranslatorFunctionType }) => {
  return (
    <Container>
      <DivText>
        <Content>
          <Title>
            {t(
              'subscription.title',
              `Print a little or a lot, there’s an option for everyone`
            )}
          </Title>
          <Description>
            {t(
              'subscription.description',
              `Your Platinum subscription includes hassle-free ink delivery and prepaid cartridge recycling. There are plans for occasional and frequent printers, and an option to add paper delivery to your plan.`
            )}
          </Description>
        </Content>
      </DivText>
      <DivImg>
        <picture>
          <source srcSet={BannerImgXL} media="(min-width: 1920px)" />
          <source srcSet={BannerImgL} media="(min-width: 1440px)" />
          <source srcSet={BannerImgM} media="(min-width: 1024px)" />
          <Image src={BannerImgXS} />
        </picture>
      </DivImg>
    </Container>
  )
}
