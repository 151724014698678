import styled from 'styled-components'
import { LabelStyled } from '../Typography'
import theme from '../../constants/theme'

export const Wrapper = styled(LabelStyled)`
  position: relative;
  margin: 8px 0;
`
export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  &:checked ~ * {
    border-color: ${(props) => props.theme.colors.secondary};
  }
  &:hover ~ *,
  &:focus-visible ~ * {
    background-color: ${theme.color.primaryLight};
    border-color: ${(props) => props.theme.colors.secondary};
  }

  &:focus-visible ~ *:after {
    opacity: 1;
  }
`
export const Content = styled.div`
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.borderOptions};
  position: relative;
  padding: 11px 24px;
  cursor: pointer;
  margin: 10px 0px;
  justify-content: space-between;
  display: flex;

  &:after {
    content: ' ';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid ${theme.color.primaryDarker};
    border-radius: 14px;
    position: absolute;
    top: 0;
    left: 0;
    inset: -4px;
    opacity: 0;
  }
`
export const Description = styled.span`
  display: contents;
`
export const Label = styled.span`
  font-style: normal;
  color: ${(props) => props.theme.colors.primary};
  margin-left: 8px;
`
export const Price = styled.span`
  font-style: normal;
  color: ${(props) => props.theme.colors.secondary};
`
