import React from 'react'
import { TranslatorFunctionType } from '../../types/localization'

import {
  DescriptionText,
  Description,
  DivText,
  DivImg,
  Image,
  Title,
  Container
} from './HowDoIKnowPagesModal.styles'

import BannerXL from './assets/images/banner-xl.jpg'
import BannerL from './assets/images/banner-l.jpg'
import BannerM from './assets/images/banner-m.jpg'
interface Props {
  t: TranslatorFunctionType
}

export const HowDoIKnowPagesModal = ({ t }: Props) => {
  const instantInkUrl = 'https://hpinstantink.com/users/signin'

  return (
    <>
      <Container>
        <DivImg>
          <picture>
            <source srcSet={BannerXL} media="(min-width: 1920px)" />
            <source srcSet={BannerL} media="(min-width: 1440px)" />
            <source srcSet={BannerM} media="(min-width: 1024px)" />
            <Image src={BannerM} />
          </picture>
        </DivImg>
        <DivText>
          <Title>
            {t(
              'how-do-i-know-pages-modal.title1',
              'Print less or more in a month?'
            )}
          </Title>
          <Description>
            <DescriptionText>
              {t(
                'how-do-i-know-pages-modal.description1',
                `If you don’t use all your plan pages in a month, the unused pages will be kept in your account as rollover pages. You can continue to roll over up to two or three times the number of pages in your monthly plan (varies by printer model and type). See your dashboard for specific rollover page counts.`
              )}
            </DescriptionText>
          </Description>
          <Description>
            <DescriptionText>
              {t(
                'how-do-i-know-pages-modal.description2',
                `If you've printed all plan and rollover pages, additional pages are available in sets of 10-15 pages, depending on your plan, for only [$0.99] per set.`
              )}
            </DescriptionText>
          </Description>
          <Description>
            <DescriptionText>
              {t(
                'how-do-i-know-pages-modal.description3.1',
                `If your printer is connected to the Internet, we'll send a reminder email when you're running low on the pages in your plan. You can easily change your plan by signing in at `
              )}
              <a href={instantInkUrl} target="blank">
                hpinstantink.com/users/signin
              </a>
              {t(
                'how-do-i-know-pages-modal.description3.2',
                ` and selecting “Change Plan”.`
              )}
            </DescriptionText>
          </Description>
        </DivText>
      </Container>
    </>
  )
}
