import React from 'react'

export const IconPolicies = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M14.5,7h-7C7.2236328,7,7,6.7763672,7,6.5S7.2236328,6,7.5,6h7
 C14.7763672,6,15,6.2236328,15,6.5S14.7763672,7,14.5,7z M12,9.5C12,9.2236328,11.7763672,9,11.5,9h-4
 C7.2236328,9,7,9.2236328,7,9.5S7.2236328,10,7.5,10h4C11.7763672,10,12,9.7763672,12,9.5z M11,12.5
 c0-0.2763672-0.2236328-0.5-0.5-0.5h-3C7.2236328,12,7,12.2236328,7,12.5S7.2236328,13,7.5,13h3
 C10.7763672,13,11,12.7763672,11,12.5z M16.5,11c-1.37854,0-2.5,1.1215-2.5,2.5s1.12146,2.5,2.5,2.5s2.5-1.1215,2.5-2.5
 S17.87854,11,16.5,11 M16.5,10c1.9329834,0,3.5,1.5670204,3.5,3.5S18.4329834,17,16.5,17S13,15.4329796,13,13.5
 S14.5670166,10,16.5,10L16.5,10z M12.5,20c0.2761421,0,0.5-0.2238579,0.5-0.5S12.7761421,19,12.5,19H6c-0.5513916,0-1-0.4486008-1-1
 V4c0-0.5513999,0.4486084-1,1-1h10c0.5513916,0,1,0.4486001,1,1v4.5C17,8.7761421,17.2238579,9,17.5,9S18,8.7761421,18,8.5V4
 c0-1.0999801-0.9000244-2-2-2l0,0H6C4.9000244,2,4,2.9000199,4,4v14c0,1.0999794,0.9000244,2,2,2H12.5z M14,21.1411304
 c0,0.2916145,0.2393799,0.5007248,0.5006104,0.5007248c0.0821533,0,0.1665039-0.0206661,0.2462158-0.0659351l1.5063477-0.8552399
 c0.0765381-0.0434666,0.1616211-0.0651989,0.2468262-0.0651989s0.1702881,0.0217323,0.2468262,0.0651989l1.5063477,0.8552399
 c0.0797119,0.0452576,0.1640625,0.0659351,0.2462158,0.0659351C18.7606201,21.6418552,19,21.4327679,19,21.1411304V18.08004
 c0-0.2761421-0.2238579-0.5-0.5-0.5s-0.5,0.2238579-0.5,0.5v0.7122841v1.4899216l-0.7592773-0.4311161
 C17.0152588,19.7231388,16.7591553,19.6554794,16.5,19.6554794s-0.5152588,0.0676594-0.7406006,0.195591L15,20.2822456v-1.4899292
 V18.08004c0-0.2761421-0.2238579-0.5-0.5-0.5s-0.5,0.2238579-0.5,0.5V21.1411304z"
      >
        <title>Policies</title>
      </path>
    </svg>
  )
}

export default IconPolicies
