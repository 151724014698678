import React from 'react'
import { OptionButton } from '../../../../components/OptionButton/OptionButton'

import PaperXL from '../../assets/images/paper-xl.png'
import PaperL from '../../assets/images/paper-l.png'
import PaperM from '../../assets/images/paper-m.png'
import PaperXS from '../../assets/images/paper-xs.png'

import {
  Image,
  Col1,
  Col2,
  Section,
  Title,
  Link,
  DivDescription,
  Wrapper,
  OptionGroup,
  BottomDescription,
  Description
} from '../Common/Styles/ProductPage.styles'
import { TranslatorFunctionType } from '../../../../types/localization'
import useAnalytics from '../../../../hooks/useAnalytics'
import { PaperAddonParsedType } from '../../../../types/catalogGatewayParsed'

interface Props {
  t: TranslatorFunctionType
  handlePapersChange: (event: EventTarget) => void
  openHowDoIKnowModal: () => void
  papers: string
  options: PaperAddonParsedType
}

export const ChoosePapers = ({
  t,
  handlePapersChange,
  openHowDoIKnowModal,
  papers,
  options
}: Props) => {
  const { events, publishEvent } = useAnalytics()

  const openModal = () => {
    publishEvent(events.ClickNeedPaper())
    openHowDoIKnowModal()
  }

  return (
    <Section>
      <Col1>
        <picture>
          <source srcSet={PaperXL} media="(min-width: 1920px)" />
          <source srcSet={PaperL} media="(min-width: 1440px)" />
          <source srcSet={PaperM} media="(min-width: 1024px)" />
          <Image src={PaperXS} />
        </picture>
      </Col1>
      <Col2>
        <DivDescription>
          <Title>
            {t('product-page.papers.title', 'Add paper to your subscription')}
          </Title>
          <Description>
            {t(
              'product-page.papers.description',
              'Don`t worry about buying paper anymore. The HP Paper Service fulfills all your paper needs every month. Just add it to your subscription and forget about buying paper.'
            )}
          </Description>
          <Link onClick={openModal} data-testid="papers-quantity-button">
            {t('product-page.papers.link', 'Why add paper?')}
          </Link>
        </DivDescription>
        <Wrapper>
          <OptionGroup>
            <OptionButton
              name="papers"
              checked={papers === 'no'}
              value={'no'}
              description={'No, thanks'}
              label={''}
              testId="need-paper-no"
              onChangeValue={handlePapersChange}
            />
            {options && (
              <OptionButton
                name="papers"
                checked={papers === options.id}
                value={options.id}
                description={'Yes, add it to my subscription'}
                price={options.formattedPrice}
                label={''}
                testId="need-paper-yes"
                onChangeValue={handlePapersChange}
              />
            )}
          </OptionGroup>
        </Wrapper>
        <BottomDescription>
          {t(
            'product-page.papers.bottom-description',
            'An early cancellation fee applies if you cancel in the first 24 months'
          )}
        </BottomDescription>
      </Col2>
    </Section>
  )
}
