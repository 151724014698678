import React from 'react'

export const IconPrinter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19,7h-2V5c0-1.1030273-0.8974609-2-2-2H9
	C7.8969727,3,7,3.8969727,7,5v2H5C3.8969727,7,3,7.8969727,3,9v6c0,1.1025391,0.8969727,2,2,2h0.5C5.7763672,17,6,16.7763672,6,16.5
	S5.7763672,16,5.5,16H5c-0.5512695,0-1-0.4482422-1-1V9c0-0.5512695,0.4487305-1,1-1h14c0.5517578,0,1,0.4487305,1,1v6
	c0,0.5517578-0.4482422,1-1,1h-0.5c-0.2763672,0-0.5,0.2236328-0.5,0.5s0.2236328,0.5,0.5,0.5H19c1.1025391,0,2-0.8974609,2-2V9
	C21,7.8969727,20.1025391,7,19,7z M8,5c0-0.5512695,0.4487305-1,1-1h6c0.5517578,0,1,0.4487305,1,1v2H8V5z M9,16
	c0-0.2763672,0.2236328-0.5,0.5-0.5h5c0.2763672,0,0.5,0.2236328,0.5,0.5s-0.2236328,0.5-0.5,0.5h-5
	C9.2236328,16.5,9,16.2763672,9,16z M17.5,13h-11C6.2236328,13,6,13.2236328,6,13.5S6.2236328,14,6.5,14H7v5
	c0,1.1025391,0.8969727,2,2,2h6c1.1025391,0,2-0.8974609,2-2v-5h0.5c0.2763672,0,0.5-0.2236328,0.5-0.5S17.7763672,13,17.5,13z
	 M16,19c0,0.5517578-0.4482422,1-1,1H9c-0.5512695,0-1-0.4482422-1-1v-5h8V19z M9,18c0-0.2763672,0.2236328-0.5,0.5-0.5h5
	c0.2763672,0,0.5,0.2236328,0.5,0.5s-0.2236328,0.5-0.5,0.5h-5C9.2236328,18.5,9,18.2763672,9,18z M6.5,10h-1
	C5.2236328,10,5,9.7763672,5,9.5S5.2236328,9,5.5,9h1C6.7763672,9,7,9.2236328,7,9.5S6.7763672,10,6.5,10z"
      >
        <title>Printer</title>
      </path>
    </svg>
  )
}

export default IconPrinter
