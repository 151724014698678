import React, { useState } from 'react'
import {
  AccordionTitle,
  AccordionBody,
  AccordionContent,
  AccordionTitleContainer,
  Separator
} from './Accordion.styles'

export const Accordion = (
  { title, content, onClickTitle, testId }: AccordionContentType,
  i: number
) => {
  const [isActive, setIsActive] = useState(false)

  const setActive = (e) => {
    e.preventDefault()

    if (onClickTitle)
      onClickTitle(
        isActive ? 'ControlAccordianCollapsed' : 'ControlAccordianExpanded'
      )

    setIsActive(!isActive)
  }

  return (
    <AccordionTitleContainer data-testid={testId} key={i}>
      <AccordionTitle
        className={isActive ? 'isExpanded' : null}
        onClick={setActive}
        aria-expanded={isActive}
      >
        {title}
      </AccordionTitle>
      <AccordionBody className={isActive ? 'isExpanded' : null}>
        <Separator />
        <AccordionContent
          dangerouslySetInnerHTML={{
            __html: content
          }}
        ></AccordionContent>
      </AccordionBody>
    </AccordionTitleContainer>
  )
}

export interface AccordionContentType {
  title: string
  content: string
  onClickTitle?: (
    accordianAction: 'ControlAccordianExpanded' | 'ControlAccordianCollapsed'
  ) => void
  testId?: string
}
