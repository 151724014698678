import React from 'react'

export const IconCalendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19,4.5001831h-2v-1c0-0.2763672-0.2236328-0.5-0.5-0.5s-0.5,0.2236328-0.5,0.5v3
	c0,0.2763672,0.2236328,0.5,0.5,0.5s0.5-0.2236328,0.5-0.5v-1h2c0.5517578,0,1,0.4487305,1,1v2.5H4v-2.5
	c0-0.5512695,0.4487305-1,1-1h0.5c0.2763672,0,0.5-0.2236328,0.5-0.5s-0.2236328-0.5-0.5-0.5H5c-1.1030273,0-2,0.8969727-2,2v12.5
	c0,1.1025391,0.8969727,2,2,2h14c1.1025391,0,2-0.8974609,2-2v-12.5C21,5.3971558,20.1025391,4.5001831,19,4.5001831z
	 M20,19.0001831c0,0.5517578-0.4482422,1-1,1H5c-0.5512695,0-1-0.4482422-1-1v-9h16V19.0001831z M7,6.5001831v-3
	c0-0.2763672,0.2236328-0.5,0.5-0.5s0.5,0.2236328,0.5,0.5v1h6.5c0.2763672,0,0.5,0.2236328,0.5,0.5s-0.2236328,0.5-0.5,0.5H8v1
	c0,0.2763672-0.2236328,0.5-0.5,0.5S7,6.7765503,7,6.5001831z"
      >
        <title>Calendar</title>
      </path>
    </svg>
  )
}

export default IconCalendar
