import { Stack } from '@jarvis/web-stratus-client'

import DevEnv from './dev.env'
import PieEnv from './pie.env'
import StageEnv from './stage.env'
import ProdEnv from './prod.env'

export default {
  [Stack.dev]: DevEnv,
  [Stack.pie]: PieEnv,
  [Stack.stage]: StageEnv,
  [Stack.prod]: ProdEnv
}
