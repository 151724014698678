import { useEffect, useState } from 'react'

interface IntersectionObserverHook {
  visible: boolean
}

const useIntersectionObserver = <T extends Element = Element>(
  target: React.RefObject<T>,
  options?: IntersectionObserverInit
): IntersectionObserverHook => {
  const [state, setState] = useState<IntersectionObserverHook>({
    visible: false
  })
  const defaultOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
    ...options
  }

  const intersectionObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      setState({
        visible: entries[0].isIntersecting
      })
    },
    defaultOptions
  )

  useEffect(() => {
    intersectionObserver.observe(target.current)
    return () => intersectionObserver.disconnect()
  }, [])

  return state
}

export default useIntersectionObserver
