import React, { useState } from 'react'

import useAnalytics from '../../hooks/useAnalytics'
import { TranslatorFunctionType } from '../../types/localization'

import Button from '../Button'
import {
  ButtonDiv,
  Container,
  Content,
  Disclaimers as DisclaimersList,
  DisclaimersTitle,
  FadeOut,
  Title
} from './Disclaimers.styles'

export const Disclaimers = ({ t }: { t: TranslatorFunctionType }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { publishEvent, events } = useAnalytics()

  const ClickExpand = () => {
    publishEvent(events.DisclaimersViewMore())
    setIsExpanded(!isExpanded)
  }

  return (
    <Container>
      <Title>{t('disclaimers.header', 'Disclaimers')}</Title>
      <Content className={isExpanded ? 'isExpanded' : ''}>
        <DisclaimersList aria-expanded={isExpanded}>
          <li>
            <p>
              <DisclaimersTitle>
                {t('disclaimers.content.service.title', '1. The Service.')}
              </DisclaimersTitle>
            </p>
            <p
              className="mb"
              dangerouslySetInnerHTML={{
                __html: t(
                  'disclaimers.content.whats-included.description',
                  'WHAT’S INCLUDED. Service includes printer, HP Instant Ink service, 24/7 hardware support, next-business-day-replacement warranty, HP Smart Advance mobile app, and automatic upgrade eligibility. Does not include internet access, paper, or taxes. Printers require ongoing connection to the internet for program participation. Internet access sold separately. Smart Advance requires the HP Smart app download. Certain features/software are available in English language only and differ between desktop and mobile applications. HP account required for full functionality. List of supported operating systems available in app stores. For more information see <a href="https://www.hpsmart.com">www.hpsmart.com</a>.'
                )
              }}
            />
            <p className="mb">
              {t(
                'disclaimers.content.warranty-and-support.description',
                'WARRANTY AND SUPPORT. The HP Instant Ink Platinum service comes with a printer warranty which covers the printer if customer has continuously maintained an active subscription to HP Instant Ink Platinum service. Standard warranty replacement delivery is next business day, excluding holidays and weekends, in most areas within continental United States, if order is placed by 2:30 p.m. Central time. If you cancel your subscription, your warranty service ends, and you may have the option to purchase an extended warranty from HP. Standard delivery is next business day, excluding holidays and weekends, in most areas within continental United States, if order is placed by 2:30 p.m. Central time. Customers must call HP Instant Ink Platinum support at 1-888-447-0148 to receive support. Platinum customers have access to 24/7 support for hardware issues. Limited support hours may apply for subscription-related questions. After two continuous years with an active subscription, HP will notify customer at supplied email address that customer is eligible to receive an upgrade. At that time, customer may choose to upgrade immediately, or delay the upgrade until any time after notification if they have maintained an uninterrupted subscription. Customer has the option to keep current printer or return it to HP at HP’s expense. Available printer models may change from time to time.'
              )}
            </p>
            <p className="mb">
              {t(
                'disclaimers.content.no-upfront-cost.description',
                'NO UPFRONT COST. There is no upfront cost to begin a subscription to Instant Ink Platinum. The cost of the printer is included in the all-inclusive monthly fee. The monthly fee will be charged automatically each month until canceled.'
              )}
            </p>
            <p>
              {t(
                'disclaimers.content.cancelation.description',
                'CANCELATION AND 30-DAY NO-OBLIGATION TRIAL PERIOD. Platinum subscriptions come with a 30-day no-obligation trial period. If customer cancels their subscription within 30 days of signup and returns the printer to HP within ten days of canceling, the customer will not be charged. After that, if customer cancels their subscription within the first year after completing enrollment in Platinum or transferring Platinum service to a printer upgrade, customer must pay a cancelation fee equal to the list price of the printer ($160 for Envy, $200 for Envy Inspire, or $270 for OfficeJet Pro). If customer cancels their subscription within the second year, the cancelation fee is half the list price of the printer. If customer cancels their subscription more than two years after completing enrollment or transferring service to an upgrade, there is no cancelation fee. Upon cancelation at any time outside the thirty-day trial period, customer may keep the printer or return it to HP. If customer cancels the Platinum service, they will have to either subscribe to the standard Instant Ink service to continue ink delivery, or purchase retail ink cartridges. How to cancel. Customer may cancel their subscription anytime by contacting HP Instant Ink Platinum Customer Care at 1-888-447-0148, or by canceling online via the Instant Ink dashboard.'
              )}
            </p>
          </li>
          <li>
            <DisclaimersTitle>
              {t(
                'disclaimers.unlimited-printing-plan.title',
                '2. NEVER RUN OUT CLAIM'
              )}
            </DisclaimersTitle>{' '}
            {t(
              'disclaimers.unlimited-printing-plan.description',
              'Based on plan usage, internet connection to eligible HP printer, valid payment method, email address, and delivery service in your geographic area.'
            )}
          </li>
          <li>
            <DisclaimersTitle>
              {t(
                'disclaimers.content.hp-leadership-claim.title',
                '3. HP INSTANT INK PLATINUM RECEIVED CARBONNEUTRAL® USAGE CERTIFICATION'
              )}
            </DisclaimersTitle>
            {t(
              'disclaimers.content.hp-leadership-claim.description',
              'on November 1, 2021. In accordance with The CarbonNeutral Protocol the certification covers electricity use of your printer, and life cycle emissions of your cartridge (production, transportation, packaging, and end of life management). The certification excludes emissions associated with the Welcome Kit literature, customer paper, and printer hardware manufacturing. The CarbonNeutral usage certification is supported by an ISO 14040/44 compliant and third-party reviewed lifecycle assessment (LCA) study. HP calculates the total usage carbon footprint with information unique to your printer and purchases carbon credits that adhere to the International Carbon Reduction and Offset Alliance (ICROA) standards to offset those calculated emissions.'
            )}
          </li>
        </DisclaimersList>
        <FadeOut
          className={isExpanded ? 'isExpanded' : ''}
          data-testid="fadeout-div"
        />
      </Content>
      <ButtonDiv>
        <Button
          onClick={ClickExpand}
          className={isExpanded ? 'isExpanded' : ''}
          outlined
          data-testid="disclaimers-button"
        >
          {isExpanded
            ? t('disclaimers."view-less', 'View Less')
            : t('disclaimers."view-more', 'View More')}
        </Button>
      </ButtonDiv>
    </Container>
  )
}
