import React from 'react'

export const IconShield = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12,3.000011c0.1708069,0,0.2993774,0.050621,0.3627625,0.0979919
 c2.116272,1.5817299,4.7777557,2.5549088,7.4942169,2.7402802C19.9371796,5.8437533,20,5.9148135,20,6.0000529v6.9999003
 c0,1.200449-0.0923615,2.2824993-0.2298126,2.6925182c-0.1369324,0.4083891-0.3722229,0.8337698-0.7294617,1.3143396
 c-0.6471252,0.902071-1.6064301,1.5517998-2.6673889,2.22719c-1.2807617,0.7436504-2.6779785,1.3278503-4.1528625,1.7363911
 c-0.0708923,0.01964-0.1450806,0.0296001-0.2204895,0.0296001c-0.0753937,0-0.1495819-0.0099602-0.2205048-0.0296001
 c-1.474823-0.4085102-2.8720245-0.9927101-4.1184845-1.7155113c-1.0952911-0.6962395-2.0545807-1.3459702-2.7118683-2.2619591
 c-0.3470917-0.4666595-0.5823822-0.8920403-0.7192993-1.3004198C4.0923615,15.2824621,4,14.2004023,4,12.9999533V6.0000529
 c0-0.0852394,0.0628204-0.1562996,0.1430206-0.1617699c2.7164612-0.1853714,5.3779449-1.1585503,7.4942017-2.7402725
 C11.7006073,3.050632,11.8291931,3.000011,12,3.000011 M12,2.000011c-0.3482666,0-0.6965179,0.0990028-0.961441,0.2970009
 c-1.9837036,1.4826508-4.4396667,2.3713608-6.963623,2.5435905C3.4674835,4.8820529,3,5.3911929,3,6.0000529v6.9999003
 c0,1,0.0647278,2.3632488,0.2816925,3.0104074c0.1887512,0.5629711,0.498764,1.0869102,0.865036,1.579361
 c0.7543488,1.0515404,1.824295,1.7758293,2.9778137,2.5090904c1.3549194,0.7867088,2.8323669,1.4043999,4.3880005,1.835289
 c0.1585693,0.0439205,0.3230286,0.0658875,0.487442,0.0658875c0.1644592,0,0.3288727-0.0219612,0.4874725-0.065897
 c1.5556183-0.4309006,3.0330811-1.0485897,4.3880005-1.8353004c1.1535187-0.7332592,2.223465-1.4575405,2.9778137-2.509079
 c0.366272-0.4924603,0.6762848-1.0163898,0.865036-1.579361C20.9352722,15.3631926,21,13.9999533,21,12.9999533V6.0000529
 c0-0.60886-0.4674835-1.118-1.0749359-1.1594505c-2.5239563-0.1722298-4.9799194-1.0609398-6.963623-2.5435905
 C12.6965179,2.0990138,12.3482666,2.000011,12,2.000011L12,2.000011z"
      >
        <title>Shield</title>
      </path>
    </svg>
  )
}

export default IconShield
