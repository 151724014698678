import React from 'react'

import useAnalytics from '../../../../hooks/useAnalytics'
import {
  IconCalendar,
  IconPolicies,
  IconPrinter,
  IconShield,
  IconTruck
} from '../../../../components/Icons'
import {
  Container,
  DivImg,
  Image,
  DivText,
  Icons,
  Icon,
  Title,
  TextTitle,
  TextSubTitle,
  Separator,
  Buttons,
  DivContent
} from './Hero.styles'
import BannerXL from './assets/banner-xl.jpg'
import BannerXS from './assets/banner-xs.jpg'
import BannerM from './assets/banner-m.jpg'
import BannerL from './assets/banner-l.jpg'
import { TranslatorFunctionType } from '../../../../types/localization'
import Button from '../../../../components/Button'
import { Global as globalConfig } from '../../../../configs/global'

export const Hero = ({ t }: { t: TranslatorFunctionType }) => {
  const { publishEventPromise, events } = useAnalytics()
  const { enrollPath } = globalConfig

  const GetStartedClick = () => {
    publishEventPromise(events.TopGetStarted()).then(() => {
      window.location.href = enrollPath
    })
  }

  return (
    <Container>
      <DivImg>
        <picture>
          <source srcSet={BannerXL} media="(min-width: 1920px)" />
          <source srcSet={BannerL} media="(min-width: 1440px)" />
          <source srcSet={BannerM} media="(min-width: 1024px)" />
          <Image src={BannerXS} alt="benefits-banner" />
        </picture>
      </DivImg>
      <DivContent>
        <Title>
          <TextTitle>{t('hero.title', 'INSTANT INK PLATINUM')}</TextTitle>
          <TextSubTitle>
            {t('hero.subtitle', 'The all-inclusive print subscription from HP')}
          </TextSubTitle>
          <Separator />
        </Title>
        <DivText>
          <Icons>
            <Icon>
              <div>
                <IconPrinter />
              </div>
              <div>{t('hero.icon1', 'New HP Printer')}</div>
            </Icon>
            <Icon>
              <div>
                <IconTruck />
              </div>
              <div>{t('hero.icon2', 'Hassle-free ink delivery')}</div>
            </Icon>
            <Icon>
              <div>
                <IconShield />
              </div>
              <div>{t('hero.icon3', `Warranty that doesn't expire`)}</div>
            </Icon>
            <Icon>
              <div>
                <IconPolicies />
              </div>
              <div>
                {t('hero.icon4', 'Upgrade eligibility every two years')}
              </div>
            </Icon>
            <Icon>
              <div>
                <IconCalendar />
              </div>
              <div>{t('hero.icon5', '30 day risk-free trial')}</div>
            </Icon>
          </Icons>
        </DivText>
        <Buttons>
          <Button
            onClick={GetStartedClick}
            data-testid="hero-get-started-button"
          >
            {t('hero.button1', 'Get Started')}
          </Button>
        </Buttons>
      </DivContent>
    </Container>
  )
}
