import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CatalogGatewayParsedContentType,
  PaperAddonParsedType
} from '../../types/catalogGatewayParsed'
import { MfePropsType } from '../../types/mfeProps'

import ProfessionalTheme from './assets/themes/professional'
import EssentialTheme from './assets/themes/essential'
import VersatileTheme from './assets/themes/versatile'

import useScrollRestoration from '../../hooks/useScrollRestoration'
import useBackToLandingPage from '../../hooks/useBackToLandingPage'
import useSessionStorage from '../../hooks/useSessionStorage'
import useShell from '../../hooks/useShell'
import useAnalytics from '../../hooks/useAnalytics'
import { useCatalog } from '../../hooks/useCatalog'
import { useCheckout } from '../../hooks/useCheckout'
import { useInkSubscriptions } from '../../hooks/useInkSubscriptions'

import {
  Container,
  Content,
  ContainerEssential,
  ContainerVersatile,
  ContainerProfessional,
  Background
} from './components/Common/Styles/ProductPage.styles'
import ChooseYourPrinter from './components/ChooseYourPrinter'
import ChoosePages from './components/ChoosePages'
import ChoosePapers from './components/ChoosePapers'
import PlanOverview from './components/PlanOverview'
import Modal from '../../components/Modal'
import ComparisonModal from './components/ProductComparison'
import HowDoIKnowPagesModal from '../../components/HowDoIKnowPagesModal'
import HowDoIKnowPapersModal from '../../components/HowDoIKnowPapersModal'
import FloatingPlanOverview from './components/FloatingPlanOverview'
import VisibilityObserver from '../../components/VisibilityObserver/'
import FullScreenSpinner from '../../components/FullScreenSpinner'
import Onboarding from '../../components/Onboarding'
import FullScreenError from '../../components/Error'

type ChoicesType = {
  plan?: string
  pages?: string
  papers?: string
}

export const ProductPage = ({ t, localization }: MfePropsType) => {
  const [theme, setTheme] = useState(EssentialTheme)
  const { planName } = theme
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('comparison')
  const [choices, setChoices, removeItem] = useSessionStorage<ChoicesType>(
    'choices',
    {}
  )
  const [plan, setPlan] = useState<string>(() =>
    Object.keys(choices).length > 0 ? choices.plan : planName
  )
  const [pages, setPages] = useState<string>()
  const [pagesOptions, setPagesOptions] = useState<
    CatalogGatewayParsedContentType[]
  >()
  const [papersOptions, setPapersOptions] = useState<PaperAddonParsedType>()
  const [papers, setPapers] = useState<string>('no')
  const [sectionDisplayed, setSectionDisplayed] = useState('ChoosePrinter')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false)
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [totalWithCurrency, setTotalWithCurrency] = useState<string>('$0')
  const [
    recommendInk,
    setRecommendInk
  ] = useState<CatalogGatewayParsedContentType>({})

  const flags = useFlags()
  const { isLoggedIn: loggedIn, authProvider, serviceRouting } = useShell()
  const { events, publishEvent } = useAnalytics()
  const { checkout } = useCheckout()
  const { getSuggestedChoices } = useInkSubscriptions()
  const { data: apiContent, error: errorCatalog } = useCatalog(localization)

  const anonymousCheckoutEnabled = flags?.['anonymousCheckout']
  const isPaperEnabled = flags?.['showAdditionalPaperFlow']

  useScrollRestoration()
  useBackToLandingPage()

  useEffect(() => {
    if (!!errorCatalog === true) {
      setIsLoading(false)
      setIsError(true)
    }
  }, [errorCatalog])

  useEffect(() => {
    if (!apiContent) return

    setPagesOptions(apiContent[plan])
    handleOnboarding(loggedIn)
    setIsLoggedIn(loggedIn)
    toggleTheme(plan)
    const indexSelected = getIndex(apiContent[plan].length)
    handlePages(plan, apiContent[plan][indexSelected].id, apiContent)

    if (Object.keys(choices).length > 0) {
      setPlan(choices.plan)
    }
    setIsLoading(false)
  }, [apiContent])

  useEffect(() => {
    if (apiContent) {
      const papersOptions = apiContent[plan].find(({ id }) => id === pages)

      if (!!papersOptions === false) {
        return
      }

      const hasPapers = papers !== 'no' && isPaperEnabled

      setTotal(
        hasPapers
          ? Number((total + papersOptions.paperAddon.price).toFixed(2))
          : papersOptions.price
      )
      handleTotalWithCurrency(
        hasPapers
          ? Number((total + papersOptions.paperAddon.price).toFixed(2))
          : papersOptions.price,
        papersOptions.currency
      )
    }
  }, [pages, papers])

  const handleTotalWithCurrency = (value: number, currency: string) => {
    const valueFormatted = value.toLocaleString(localization.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    })
    setTotalWithCurrency(valueFormatted)
  }

  const handlePages = (
    selectedPlan: string,
    defaultPage: string,
    inkRecommended?: CatalogGatewayParsedContentType
  ) => {
    setPagesOptions(apiContent[selectedPlan])
    if (Object.keys(choices).length > 0 && choices.plan === selectedPlan) {
      setPages(choices.pages)
      handlePapers(selectedPlan, choices.pages)
      return
    }

    if (
      inkRecommended &&
      Object.keys(inkRecommended).length > 0 &&
      apiContent[selectedPlan].find(({ id }) => id === inkRecommended.id)
    ) {
      setPages(inkRecommended.id)
      handlePapers(selectedPlan, inkRecommended.id)
      return
    }

    setPages(defaultPage)
    handlePapers(selectedPlan, defaultPage)
  }

  const handlePapers = (selectedPlan: string, selectedPages: string) => {
    if (!isPaperEnabled) return

    const papersOptions = apiContent[selectedPlan].find(
      ({ id }) => id === selectedPages
    )
    if (!!papersOptions === false) {
      return
    }

    setPapers('no')
    if (Object.keys(choices).length > 0 && choices.plan === selectedPlan) {
      setPapers(choices.papers)
    }

    setPapersOptions(papersOptions.paperAddon)
  }

  const getIndex = (length: number): number => {
    if (length <= 2) {
      return 0
    }
    return Math.trunc(length / 2)
  }

  const handlePlanChange = (event): void => {
    const selectedPlan = event.target.value
    setPlan(selectedPlan)
    setPagesOptions(apiContent[selectedPlan])
    toggleTheme(selectedPlan)
    const indexSelected = getIndex(apiContent[selectedPlan].length)
    handlePages(
      selectedPlan,
      apiContent[selectedPlan][indexSelected].id,
      recommendInk
    )
  }

  const handlePapersChange = (event): void => {
    setPapers(event.target.value)
  }

  const handlePagesChange = (event): void => {
    setPages(event.target.value)
    handlePapers(plan, event.target.value)
  }

  const handleFinished = async () => {
    if (Object.keys(choices).length === 0) {
      setIsLoading(true)
      const suggestedPlan = await getSuggestedChoices(apiContent)
      setIsLoading(false)
      if (suggestedPlan) {
        setRecommendInk(suggestedPlan)
        setPlan(suggestedPlan.planName)
        toggleTheme(suggestedPlan.planName)
        handlePages(suggestedPlan.planName, suggestedPlan.id, suggestedPlan)
      }
    }
  }

  const toggleTheme = (themeName: string): void => {
    switch (themeName) {
      case 'Versatile':
        setTheme(VersatileTheme)
        break
      case 'Professional':
        setTheme(ProfessionalTheme)
        break
      default:
        setTheme(EssentialTheme)
    }
  }

  const handleLogin = () => {
    publishEvent(events.ClickLogin())
    authProvider.forceLogin()
  }

  const handleOnboarding = (isLogged: boolean) => {
    setShouldShowOnboarding(isLogged)
  }

  const goToCart = async (e, action) => {
    e.preventDefault()

    setIsLoading(true)

    publishEvent(
      events.ConfigurationPrinterGoToCart(
        {
          printer: plan,
          plan: apiContent[plan].find(({ id }) => id === pages.toString())
            .pages,
          paper: isPaperEnabled ? 'yes' : 'no'
        },
        sectionDisplayed,
        action
      )
    )

    if (isLoggedIn === false && !anonymousCheckoutEnabled) {
      storeChoices()
      handleLogin()
      return
    }

    await handleCheckout()
  }

  const handleCheckout = async () => {
    const notLoggedAndIsAnonymousCheckout =
      isLoggedIn === false && anonymousCheckoutEnabled
    try {
      const response = await checkout(pages, notLoggedAndIsAnonymousCheckout)
      if (!!response.id === true) {
        removeItem()
        serviceRouting.closeServiceInstance({
          resultData: {
            result: 'success',
            output: {
              HaaS: {
                checkoutId: response.id
              }
            }
          }
        })
      }
    } catch (e) {
      setIsLoading(false)
      setIsError(true)
    }
  }

  const storeChoices = () => {
    const dataToStore: ChoicesType = {
      plan,
      pages
    }

    if (isPaperEnabled) dataToStore.papers = papers

    setChoices(dataToStore)
  }

  const openModal = (modalType: string) => {
    setModalType(modalType)
    setShowModal(true)
  }

  if (isLoading) return <FullScreenSpinner />
  if (isError)
    return (
      <FullScreenError
        onRetryClick={() => (window.location.href = '/choose-plan')}
      />
    )

  if (shouldShowOnboarding)
    return (
      <Onboarding
        onFinished={() => {
          setShouldShowOnboarding(false)
          handleFinished()
        }}
      />
    )

  return (
    <ThemeProvider theme={theme}>
      <main>
        <Container>
          <ContainerEssential />
          <ContainerVersatile />
          <ContainerProfessional />
          <Content>
            <VisibilityObserver
              observerOptions={{ threshold: 0.5 }}
              onVisible={() => {
                setSectionDisplayed('ChoosePrinter')
              }}
              displayedEvent={events.ChoosePrinterDisplayed}
            >
              <ChooseYourPrinter
                t={t}
                handlePlanChange={(event) => handlePlanChange(event)}
                openComparisonModal={() => openModal('comparisonModal')}
                plan={plan}
              />
            </VisibilityObserver>
            <VisibilityObserver
              observerOptions={{ threshold: 0.5 }}
              onVisible={() => {
                setSectionDisplayed('ChoosePlan')
              }}
              displayedEvent={events.ChoosePlanDisplayed}
            >
              <ChoosePages
                t={t}
                handlePagesChange={(event) => handlePagesChange(event)}
                openHowDoIKnowModal={() => openModal('pagesModal')}
                pages={pages}
                options={pagesOptions}
              />
            </VisibilityObserver>
            {isPaperEnabled && (
              <VisibilityObserver
                observerOptions={{ threshold: 0.5 }}
                onVisible={() => {
                  setSectionDisplayed('ChoosePaper')
                }}
                displayedEvent={events.ChoosePaperDisplayed}
              >
                <ChoosePapers
                  t={t}
                  handlePapersChange={(event) => handlePapersChange(event)}
                  openHowDoIKnowModal={() => openModal('papersModal')}
                  papers={papers}
                  options={papersOptions}
                />
              </VisibilityObserver>
            )}
            <VisibilityObserver
              observerOptions={{ threshold: 0.5 }}
              onVisible={() => {
                setSectionDisplayed('Order')
              }}
              displayedEvent={events.OrderDisplayed}
            >
              <PlanOverview
                t={t}
                pages={
                  apiContent[plan].find(({ id }) => id === pages)
                    ? apiContent[plan].find(({ id }) => id === pages).pages
                    : '0'
                }
                papers={papers}
                total={totalWithCurrency}
                isPaperEnabled={isPaperEnabled}
                goToCart={goToCart}
                buttonDisabled={isLoading}
              />
            </VisibilityObserver>
          </Content>
          <FloatingPlanOverview
            t={t}
            total={totalWithCurrency}
            goToCart={goToCart}
            buttonDisabled={isLoading}
          />
          <Background />
        </Container>
        <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
          {modalType === 'comparisonModal' && (
            <ComparisonModal {...{ t }} selectedPlan={plan} />
          )}
          {modalType === 'pagesModal' && <HowDoIKnowPagesModal {...{ t }} />}
          {modalType === 'papersModal' && <HowDoIKnowPapersModal {...{ t }} />}
        </Modal>
      </main>
    </ThemeProvider>
  )
}
