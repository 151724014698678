export const color = {
  white: '#fff',
  black: '#212121',
  primary: 'rgb(2, 122, 174)',
  primaryLight: 'rgba(2, 122, 174, 0.05)',
  primaryHover: 'rgb(3, 92, 132)',
  primaryActive: 'rgb(1, 70, 103)',
  secondary: 'transparent',
  secondaryHover: 'rgba(2, 122, 174, 0.05)',
  secondaryActive: 'rgba(2, 122, 174, 0.1)'
}

// gutter margin to space out the content from the edge of the browser viewport (horizontally)
export const gutter = {
  xs: '20px',
  md: '40px'
}

export const font = {
  body: 'Forma DJR Micro',
  heading: 'Forma DJR Display'
}

export default {
  color,
  gutter,
  font
}
