import PrinterImgXS from '../images/professional/printer-xs.png'
import PrinterImgM from '../images/professional/printer-m.png'
import PrinterImgL from '../images/professional/printer-l.png'
import PrinterImgXL from '../images/professional/printer-xl.png'

import ResumeImgXS from '../images/professional/resume-xs.png'
import ResumeImgM from '../images/professional/resume-m.png'
import ResumeImgL from '../images/professional/resume-l.png'
import ResumeImgXL from '../images/professional/resume-xl.png'

import BackgroundImgXS from '../images/professional/background-xs.png'
import BackgroundImgM from '../images/professional/background-m.png'
import BackgroundImgL from '../images/professional/background-l.png'
import BackgroundImgXL from '../images/professional/background-xl.png'

export default {
  planName: 'Professional',
  description: 'Best for hybrid workers, freelancers and businesses.',
  printerName: 'HP Officejet Pro',
  colors: {
    primary: '#737373',
    secondary: '#3AB4EB',
    background: '#363636',
    borderOptions: '#737373',
    text: '#fff'
  },
  images: {
    printer: {
      extraSmall: PrinterImgXS,
      medium: PrinterImgM,
      larger: PrinterImgL,
      extraLarge: PrinterImgXL
    },
    resume: {
      extraSmall: ResumeImgXS,
      medium: ResumeImgM,
      larger: ResumeImgL,
      extraLarge: ResumeImgXL
    },
    background: {
      extraSmall: BackgroundImgXS,
      medium: BackgroundImgM,
      larger: BackgroundImgL,
      extraLarge: BackgroundImgXL
    }
  }
}
