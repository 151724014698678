import React, { useState } from 'react'
import { TranslatorFunctionType } from '../../../../types/localization'
import useMediaQuery from '../../../../hooks/useMediaQuery'

import {
  Box,
  BoxDiv,
  BoxText,
  BoxTextPrice,
  BoxButton
} from './FloatingGetStarted.styles'

import PrinterImg from '../../../ProductPage/assets/images/essential/printer-xs.png'

interface Props {
  t: TranslatorFunctionType
  onClick: () => void
}

export const FloatingGetStarted = ({ t, onClick }: Props) => {
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const defaultBottom = -100
  const [bottomTransition, setBottomTransition] = useState(defaultBottom)

  window.addEventListener('scroll', () => {
    if (window.scrollY > 200 && bottomTransition == defaultBottom)
      setBottomTransition(25)
    else if (window.scrollY < 200 && bottomTransition == 25)
      setBottomTransition(defaultBottom)
  })

  return (
    <BoxDiv>
      <Box bottom={bottomTransition}>
        {!isMobile && (
          <img
            style={{ width: '100px' }}
            src={PrinterImg}
            alt="printer-get-started"
          />
        )}
        <BoxText>
          <p>{t('floating-get-started.box-text', 'Get Started from')}</p>
          <BoxTextPrice>
            {t('floating-get-started.box-text-price', '$5.99/per month')}
          </BoxTextPrice>
        </BoxText>
        <BoxButton onClick={onClick} data-testid="floating-get-started-button">
          {t('floating-get-started.box-text-button', 'Choose Plan')}
        </BoxButton>
      </Box>
    </BoxDiv>
  )
}
