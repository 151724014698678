import { useEffect, useState } from 'react'

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(() => {
    return window.matchMedia(query).matches
  })

  useEffect(() => {
    let active = true
    const queryList = window.matchMedia(query)

    const onChange = (event: MediaQueryListEvent) => {
      if (!active) return
      setMatches(event.matches)
    }

    queryList.addListener(onChange)

    return () => {
      active = false
      setMatches(false)
      queryList.removeListener(onChange)
    }
  }, [query])

  return matches
}

export default useMediaQuery
