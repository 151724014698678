import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { LDProvider } from 'launchdarkly-react-client-sdk'

import { MfePropsType } from '../types/mfeProps'

import LandingPage from './LandingPage'
import ProductPage from './ProductPage'
import { ShellProvider } from '../contexts/shell.context'

const App = (props: MfePropsType): JSX.Element => {
  return (
    <LDProvider
      clientSideID={props.properties?.launchDarklyClientId}
      user={{ key: 'test@email.com' }}
    >
      <ShellProvider value={props}>
        <Router history={props.navigation}>
          <Switch>
            <Route exact path="/">
              <LandingPage {...props} />
            </Route>
            <Route exact path="/choose-plan">
              <ProductPage {...props} />
            </Route>
          </Switch>
        </Router>
      </ShellProvider>
    </LDProvider>
  )
}

export default App
