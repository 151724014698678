import styled from 'styled-components'
import breakpoints from '../../constants/breakpoints'
import { H2Styled } from '../Typography'

export const Container = styled.section`
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 64px 20px;
  height: auto;

  @media screen and ${breakpoints.medium} {
    padding: 120px 40px;
  }

  @media screen and ${breakpoints.large} {
    padding: 130px 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 180px 80px;
  }
`

export const FAQLink = styled.div`
  margin: 32px auto 0;
  align-items: center;
  justify-content: center;

  @media screen and ${breakpoints.medium} {
    margin: 64px auto 0;
  }

  @media screen and ${breakpoints.large} {
    margin: 35px auto 0;
  }

  @media screen and ${breakpoints.xlarge} {
    margin: 42px auto 0;
  }
`

export const Title = styled(H2Styled)`
  padding: 0 0 23px;

  @media screen and ${breakpoints.large} {
    padding: 0 0 40px;
  }

  @media screen and ${breakpoints.xlarge} {
    padding: 0 0 80px;
  }
`

export const Description = styled.p`
  color: #212121;
  line-height: 24px;
  font-family: Forma DJR Micro;
  font-size: 16px;
  font-weight: normal;
  align-items: center;
`

export const ContentTitle = styled.span`
  font-family: Forma DJR Micro;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.2px;
  color: #000000;
  align-self: flex-start;
  flex: 3;
  font-size: 16px;
`

export const ContentBody = styled.div`
  display: none;
  overflow: hidden;

  &[aria-expanded='true'] {
    display: flex;
    flex-direction: column;
  }
`
