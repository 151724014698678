import React from 'react'

import { TranslatorFunctionType } from '../../../../types/localization'

import {
  Container,
  DivText,
  DivImg,
  Title,
  Description,
  LiStyled
} from './Benefits.styles'

import BannerImgXS from './Assets/Images/banner-xs.jpg'
import BannerImgM from './Assets/Images/banner-m.jpg'
import BannerImgL from './Assets/Images/banner-l.jpg'
import BannerImgXL from './Assets/Images/banner-xl.jpg'

interface Props {
  t: TranslatorFunctionType
}

export const Benefits = ({ t }: Props) => {
  return (
    <Container>
      <DivText>
        <ul data-testid="benefits-list">
          <LiStyled data-testid="benefits-1">
            <Title>{t('benefits.title1', 'Simplest way to print')}</Title>
            <Description>
              {t(
                'benefits.description1',
                'Everything you need, when you need it. Get a new printer, plus ink and paper delivered to your door before you run out, for one low monthly payment.'
              )}
            </Description>
          </LiStyled>
          <LiStyled data-testid="benefits-2">
            <Title>{t('benefits.title2', 'Change plans anytime')}</Title>
            <Description>
              {t(
                'benefits.description2',
                `Create a plan that's right for you. Choose from light to frequent printing plans and easily adjust your plan if your needs change.`
              )}
            </Description>
          </LiStyled>
          <LiStyled data-testid="benefits-3">
            <Title>{t('benefits.title3', 'Here when you need us')}</Title>
            <Description>
              {t(
                'benefits.description3',
                'Rely on around-the-clock customer support, a warranty that never expires, and next-business-day printer replacement when it matters most.'
              )}
            </Description>
          </LiStyled>
        </ul>
      </DivText>
      <DivImg>
        <picture>
          <source srcSet={BannerImgXL} media="(min-width: 1920px)" />
          <source srcSet={BannerImgL} media="(min-width: 1440px)" />
          <source srcSet={BannerImgM} media="(min-width: 1024px)" />
          <img src={BannerImgXS} alt="benefits-banner" />
        </picture>
      </DivImg>
    </Container>
  )
}
