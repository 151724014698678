import { useCallback, useContext } from 'react'
import { ShellContext } from '../contexts/shell.context'
import { DataValveCDMEvent, PublishCdmEventsOptions } from '../types/analytics'
import { IEvents, Events, CdmEvent } from '../constants/ui_events'

type UseAnalytics = {
  events: IEvents
  publishEvent: (
    event: CdmEvent | CdmEvent[],
    options?: PublishCdmEventsOptions
  ) => void
  publishEventPromise: (
    event: CdmEvent | CdmEvent[],
    options?: PublishCdmEventsOptions
  ) => Promise<void>
}

const useAnalytics = (): UseAnalytics => {
  const shell = useContext(ShellContext)

  const publishEvent = useCallback(
    (event: CdmEvent | CdmEvent[], options?: PublishCdmEventsOptions) => {
      const publishCdmEvents = shell?.analytics?.publishCdmEvents

      if (!publishCdmEvents) {
        console.error('shell.analytics.publishCdmEvents is not defined')
        return
      }

      const events: CdmEvent[] = Array.isArray(event) ? event : [event]

      const dateTime = new Date().toISOString()

      const cdmEvents = events.map(
        (item: CdmEvent): DataValveCDMEvent => ({
          dateTime,
          eventDetailType:
            'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
          eventCategory: 'simpleUi',
          eventDetail: item,
          version: '1.4.0'
        })
      )

      publishCdmEvents(cdmEvents, options)
    },
    [shell]
  )

  const publishEventPromise = useCallback(
    (event: CdmEvent | CdmEvent[], options?: PublishCdmEventsOptions) => {
      return new Promise<void>((resolve) => {
        resolve(publishEvent(event, options))
      })
    },
    [shell]
  )

  return { events: Events, publishEvent, publishEventPromise }
}

export default useAnalytics
