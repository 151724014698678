import styled from 'styled-components'
import { H2Styled } from '../../../../components/Typography'
import breakpoints from '../../../../constants/breakpoints'

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
export const Container = styled.section`
  min-height: 700px;
  position: relative;
  display: grid;
  @media screen and ${breakpoints.medium} {
    height: 100vh;
  }
  color: #fff;
`

export const DivImg = styled.div`
  height: 100%;
  grid-area: 1/-1;
  overflow: hidden;
`

export const DivText = styled.div`
  padding: 0px 20px;
  grid-area: 1/-1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media screen and ${breakpoints.medium} {
    padding: 0px;
    max-width: clamp(620px, 60%, 715px);
  }

  @media screen and ${breakpoints.large} and ${breakpoints.xlarge} {
    max-width: clamp(553px, 38%, 715px);
  }
`

export const Title = styled(H2Styled)`
  margin-bottom: 24px;
  letter-spacing: 0.02em;
  @media screen and ${breakpoints.medium} {
    text-align: center;
    margin-bottom: 24px;
  }

  @media screen and ${breakpoints.large} {
    margin-bottom: 32px;
  }

  @media screen and ${breakpoints.xlarge} {
    margin-bottom: 52px;
  }
`

export const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  text-align: left;

  @media screen and ${breakpoints.medium} {
    text-align: center;
  }

  @media screen and ${breakpoints.large} {
    font-size: 24px;
    line-height: 32px;
  }
`
