import { useEffect } from 'react'

const useBackToLandingPage = (): void => {
  useEffect(() => {
    const handleBackButtonClicked = (e) => {
      e.preventDefault()
      history.pushState(null, null, '/')
    }
    window.addEventListener('popstate', handleBackButtonClicked, {
      passive: true
    })

    return () => {
      window.removeEventListener('popstate', handleBackButtonClicked)
    }
  }, [])
}

export default useBackToLandingPage
