import styled, { css } from 'styled-components'
import breakpoints from '../../constants/breakpoints'
import theme from '../../constants/theme'

type Props = {
  disabled: boolean
  outlined: boolean
}

/**
 * @component
 * @visibleName Button
 */
export const Button = styled.button<Props>`
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;

  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid ${theme.color.primary};
  background-color: ${theme.color.primary};
  padding: 13px 24px 9px;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  font-family: ${theme.font.body};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.color.white};
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: ${theme.color.primaryHover};
    border: 1px solid ${theme.color.primaryHover};
  }

  &:active {
    background-color: ${theme.color.primaryActive};
    border: 1px solid ${theme.color.primaryActive};
  }

  @media ${breakpoints.medium} {
    display: inline-block;
    width: auto;
    font-size: 16px;
  }

  // OUTLINED VARIANT
  // ============================
  ${(props: Props) =>
    props.outlined &&
    css`
      background-color: transparent;
      color: ${theme.color.primary};
      border: 1px solid ${theme.color.primary};

      &:hover {
        background-color: ${theme.color.secondaryHover};
        color: ${theme.color.primaryHover};
        border: 1px solid ${theme.color.primaryHover};
      }
      &:active {
        background-color: ${theme.color.secondaryActive};
        color: ${theme.color.primaryActive};
        border: 1px solid ${theme.color.primaryActive};
      }
    `}

  // DISABLED VARIANT
  // ============================
  ${(props: Props) =>
    props.disabled &&
    css`
      text-decoration: none;
      text-transform: none;
      user-select: none;
      background-color: rgba(33, 33, 33, 0.05);
      border-color: rgba(33, 33, 33, 0.05);
      color: rgb(173, 173, 173);
      pointer-events: none;
      cursor: default;
    `}
`
